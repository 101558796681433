import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import update from "immutability-helper";
import DeleteLabelItem from "../Modals/DeleteLabelItem";
import DeleteAllLabelItems from "../Modals/DeleteAllLabelItems";
import LicenceNumberPopover from "../Popovers/LicenceNumberPopover";
import CertNumberPopover from "../Popovers/CertNumberPopover";
import InvoiceNumberPopover from "../Popovers/InvoiceNumberPopover";
import ContentsDescPopover from "../Popovers/ContentsDescPopover";
import CommentsPopover from "../Popovers/CommentsPopover";
import DetailedDescPopover from "../Popovers/DetailedDescPopover";
import HsTariffPopover from "../Popovers/HsTariffPopover";
import CountryOfOriginPopover from "../Popovers/CountryOfOriginPopover";
import EccnNumberPopover from "../Popovers/EccnNumberPopover";
import ItemPackageWeight from "../Modals/ItemPackageWeight";
import AesExemptionITNPopover from "../Popovers/AesExemptionITNPopover";
import ChooseAESExemptionPopover from "../Popovers/ChooseAESExemptionPopover";
import PurposeOfShipmentPopover from "../Popovers/PurposeOfShipmentPopover";
import CountryUltimateDestinationPopover from "../Popovers/CountryUltimateDestinationPopover";
import DateRangeSelector from "../Popovers/DateRangeSelector";
import TermsConditionsPaymentPopover from "../Popovers/TermsConditionsPaymentPopover";
import TaxIdPopover from "../Popovers/TaxIdPopover";
import TermsConditionsDeliveryPopover from "../Popovers/TermsConditionsDeliveryPopover";
import UrbanizationCodesPopover from "../Popovers/UrbanizationCodesPopover";
import LookupHsTariffCode from "../Modals/LookupHsTariffCode";
import ProhibitedRestrictedItem from "../Modals/ProhibitedRestrictedItem";
import LandedCostEstimate from "../Modals/LandedCostEstimate";
import InputFields from "../Subcomponents/InputFields";
import StatesTerritoriesList from "../Subcomponents/StatesTerritoriesList";
import AssistiveError from "../Subcomponents/AssistiveError";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import validation from "../../utils/validation";

import moment from "moment";

class CustomsDeclaration extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      errors: {
        nonDelvOption: "",
        contents: "",
        detailedDescription: "",
        itemValue: "",
        quantity: "",
        itemWeight: "",
        hsTariffNbr: "",
        aesExemption: "",
        pfcCode: "",
        globalError: [],
        labelItemError: [],
        phoneNbr: "",
        recipientEmailAddress: "",
        purposeOfShipment: "",
        ultimateDestinationId: "",
        invoiceDate: "",
        customerOrderNumber: "",
        paymentTerms: "",
        paymentDueDate: "",
        taxId: "",
        deliveryTerms: "",
        countryId: "",
        firstName: "",
        lastName: "",
        streetAddress: "",
        line1Addr: "",
        cityName: "",
        stateId: "",
        zipCode: ""
      },
      inEditItem: false,
      isLoaded: false,
      isDesktop: true,
      showRestrictions: true,
      showProhibitions: false,
      nonDelvOption: {},
      labelCustDec: {
        pfcCode: "",
        invoiceNbr: "",
        certificateNbr: "",
        licenseNbr: "",
        contentsDesc: "",
        comments: ""
      },
      labelItem: {
        itemDesc: "",
        itemQty: "",
        unitValue: "",
        labelItemId: "0",
        cnsCartId: String(
          this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.cnsCartId
            ? this.props.history.location.state.cnsCartId
            : 0
        ),
        // Fields added for TLC (total landed cost)
        tlcCategory: "",
        tlcSubcategory: "",
        tlcItemDetail: "",
        tlcItemColor: "",
        tlcBrand: "",
        tlcUpcCode: "",
        tlcProductLength: "",
        tlcProductWidth: "",
        tlcProductHeight: ""
      },
      weightInPounds: "",
      setWeightInOz: "",
      labelItemsClickedMobile: [],
      labelPkg: {
        containerType: ""
      },
      differentBuyer: false,
      labelGxg: {
        commercialShipmentInd: "N",
        purposeOfShipment: "",
        ultimateDestinationId: "0",
        invoiceDate: "",
        customerOrderNumber: "",
        paymentTerms: "",
        paymentDueDate: "",
        taxId: "",
        deliveryTerms: ""
      },
      recipientInfo: {
        phoneNbr: "",
        emailAddress: ""
      },
      buyerAddress: {
        countryId: "0",
        firstName: "",
        middleInit: "",
        lastName: "",
        companyName: "",
        streetAddress: "",
        aptSuiteOther: "",
        line1Addr: "",
        line2Addr: "",
        province: "",
        cityName: "",
        stateId: "-1",
        zipCode: "",
        postalCode: "",
        urbanizationCode: "",
        emailAddress: "",
        phoneNbr: ""
      },
      // TLC email and associated errors
      tlcEmail: "",
      emailValidationError: "",
      // Flag that indicates if saving the entered TLC email address was successful
      // - true = show success message
      // - false = show error message
      // - null = show no message
      emailSuccessful: null
    };

    this.itemDescMaxLength = "56";
  }

  editLabel = (e) => {
    e.preventDefault();
    this.props.setEditCartId(
      this.state.fetchedCartList.cnsCartList[0].cnsCartId
    );
    this.props.history.push("/labelInformation", {
      editItem: this.state.fetchedCartList.cnsCartList[0].cnsCartId
    });
  };

  //SET AES<ADD EXPORTLICIENCE IND CODE

  componentDidMount() {
    this.props.toggleSpinner(true);
    // If there is no history, redirect the user to the create label page
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.cnsCartId
    ) {
      this.updateScreenSize();
      window.addEventListener("resize", this.updateScreenSize);
      Promise.all([
        axios.get(
          "/go/cnsrest/fetchCustomsInfo?fetchPrefQueryString=" +
            this.props.location.state.cnsCartId
        ),
        axios.get("/go/cnsrest/fetchPreferences?fetchPrefQueryString=")
      ])
        .then((response) => {
          if (
            Object.keys(response[0].data.fieldErrors).length === 0 &&
            response[0].data.actionErrors.length === 0
          ) {
            this.setState((prevState) => {
              return {
                fetchedCartList: response[0].data,
                fetchedLabelItemList:
                  response[0].data.cnsCartList[0].labelItems,
                labelPkg: {
                  ...prevState.labelPkg,
                  containerType: response[0].data.cnsCartList[0].labelCustDec
                    ? response[0].data.cnsCartList[0].labelCustDec.contents
                        ._name
                    : ""
                },

                labelCustDec: {
                  ...prevState.labelCustDec,
                  contents:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.contents._name
                      ? response[0].data.cnsCartList[0].labelCustDec.contents
                          ._name
                      : "",
                  contentsDesc:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.contentsDesc
                      ? response[0].data.cnsCartList[0].labelCustDec
                          .contentsDesc
                      : "",
                  eelCode:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.eelCode.value
                      ? response[0].data.cnsCartList[0].labelCustDec.eelCode
                          .value
                      : "",
                  comments:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.comments
                      ? response[0].data.cnsCartList[0].labelCustDec.comments
                      : "",
                  licenseNbr:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.licenseNbr
                      ? response[0].data.cnsCartList[0].labelCustDec.licenseNbr
                      : "",
                  certificateNbr:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.certificateNbr
                      ? response[0].data.cnsCartList[0].labelCustDec
                          .certificateNbr
                      : "",
                  invoiceNbr:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.invoiceNbr
                      ? response[0].data.cnsCartList[0].labelCustDec.invoiceNbr
                      : "",
                  pfcCode:
                    response[0].data.cnsCartList[0].labelCustDec &&
                    response[0].data.cnsCartList[0].labelCustDec.pfcCode
                      ? response[0].data.cnsCartList[0].labelCustDec.pfcCode
                      : ""
                },
                labelInd: {
                  ...prevState.labelInd,
                  nonDelvOption:
                    response[0].data.cnsCartList[0].labelInd &&
                    response[0].data.cnsCartList[0].labelInd.nonDelvOption &&
                    response[0].data.cnsCartList[0].labelInd.nonDelvOption._name
                      ? response[0].data.cnsCartList[0].labelInd.nonDelvOption
                          ._name
                      : "None",
                  exportLicenseInd:
                    response[0].data.cnsCartList[0].labelInd &&
                    response[0].data.cnsCartList[0].labelInd.exportLicenseInd
                      ? response[0].data.cnsCartList[0].labelInd
                          .exportLicenseInd
                      : "N"
                },
                eelCodeOptions: response[0].data.eelCodeOptions,
                countryList: this.reorderCountryList(
                  response[0].data.countryZonesViewBean
                ),

                recipientInfo: {
                  ...prevState.recipientInfo,
                  phoneNbr:
                    response[0].data.cnsCartList[0].deliveryAddress &&
                    response[0].data.cnsCartList[0].deliveryAddress.phoneNbr
                      ? response[0].data.cnsCartList[0].deliveryAddress.phoneNbr
                      : "",
                  emailAddress:
                    response[0].data.cnsCartList[0].deliveryAddress &&
                    response[0].data.cnsCartList[0].deliveryAddress.emailAddress
                      ? response[0].data.cnsCartList[0].deliveryAddress
                          .emailAddress
                      : ""
                },

                labelGxg: {
                  ...prevState.labelGxg,
                  commercialShipmentInd:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg
                      .commercialShipmentInd
                      ? response[0].data.cnsCartList[0].labelGxg
                          .commercialShipmentInd
                      : "N",
                  purposeOfShipment:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.purposeOfShipment
                      ? response[0].data.cnsCartList[0].labelGxg
                          .purposeOfShipment
                      : "",
                  ultimateDestinationId:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg
                      .ultimateDestinationId
                      ? response[0].data.cnsCartList[0].labelGxg
                          .ultimateDestinationId
                      : "0",
                  invoiceDate:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.invoiceDate
                      ? response[0].data.cnsCartList[0].labelGxg.invoiceDate
                      : "",
                  customerOrderNumber:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.customerOrderNumber
                      ? response[0].data.cnsCartList[0].labelGxg
                          .customerOrderNumber
                      : "",
                  paymentTerms:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.paymentTerms
                      ? response[0].data.cnsCartList[0].labelGxg.paymentTerms
                      : "",
                  paymentDueDate:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.paymentDueDate
                      ? response[0].data.cnsCartList[0].labelGxg.paymentDueDate
                      : "",
                  taxId:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.taxId
                      ? response[0].data.cnsCartList[0].labelGxg.taxId
                      : "",
                  deliveryTerms:
                    response[0].data.cnsCartList[0].labelGxg &&
                    response[0].data.cnsCartList[0].labelGxg.deliveryTerms
                      ? response[0].data.cnsCartList[0].labelGxg.deliveryTerms
                      : ""
                },

                differentBuyer:
                  response[0].data.cnsCartList[0].buyerAddress &&
                  response[0].data.cnsCartList[0].buyerAddress.countryId
                    ? true
                    : false,
                buyerAddress: {
                  ...prevState.buyerAddress,
                  countryId:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.countryId
                      ? String(
                          response[0].data.cnsCartList[0].buyerAddress.countryId
                        )
                      : "0",
                  firstName:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.firstName
                      ? response[0].data.cnsCartList[0].buyerAddress.firstName
                      : "",
                  middleInit:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.middleInit
                      ? response[0].data.cnsCartList[0].buyerAddress.middleInit
                      : "",
                  lastName:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.lastName
                      ? response[0].data.cnsCartList[0].buyerAddress.lastName
                      : "",
                  companyName:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.companyName
                      ? response[0].data.cnsCartList[0].buyerAddress.companyName
                      : "",
                  streetAddress:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.line1Addr
                      ? response[0].data.cnsCartList[0].buyerAddress.line1Addr
                      : "",
                  aptSuiteOther:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.line2Addr
                      ? response[0].data.cnsCartList[0].buyerAddress.line2Addr
                      : "",
                  line1Addr:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    !this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.line1Addr
                      ? response[0].data.cnsCartList[0].buyerAddress.line1Addr
                      : "",
                  line2Addr:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    !this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.line2Addr
                      ? response[0].data.cnsCartList[0].buyerAddress.line2Addr
                      : "",
                  line3Addr:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    !this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.line3Addr
                      ? response[0].data.cnsCartList[0].buyerAddress.line3Addr
                      : "",
                  province:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    !this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.province
                      ? response[0].data.cnsCartList[0].buyerAddress.province
                      : "",
                  cityName:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.cityName
                      ? response[0].data.cnsCartList[0].buyerAddress.cityName
                      : "",
                  stateId:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.stateId
                      ? String(
                          response[0].data.cnsCartList[0].buyerAddress.stateId
                        )
                      : "-1",
                  zipCode:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.zip5
                      ? response[0].data.cnsCartList[0].buyerAddress.zip5
                      : "",
                  postalCode:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    !this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.postalCode
                      ? response[0].data.cnsCartList[0].buyerAddress.postalCode
                      : "",
                  urbanizationCode:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    this.isBuyerDomesticInitially(
                      response[0].data.cnsCartList[0].buyerAddress.countryId
                    ) &&
                    response[0].data.cnsCartList[0].buyerAddress.stateId &&
                    String(
                      response[0].data.cnsCartList[0].buyerAddress.stateId
                    ) === "49" &&
                    response[0].data.cnsCartList[0].buyerAddress
                      .urbanizationCode
                      ? response[0].data.cnsCartList[0].buyerAddress
                          .urbanizationCode
                      : "",
                  emailAddress:
                    response[0].data.cnsCartList[0].buyerAddress &&
                    response[0].data.cnsCartList[0].buyerAddress.emailAddress
                      ? response[0].data.cnsCartList[0].buyerAddress
                          .emailAddress
                      : ""
                },
                shipFromCountryCode:
                  response[0].data.cnsCartList[0].returnAddress.countryCode,
                tlcEmail:
                  response[0].data.cnsCartList[0].returnAddress.emailAddress ||
                  response[1]?.data?.userPref?.ptsTrackingEmail ||
                  this.props.userData?.custRegEmail ||
                  ""
              };
            });
            this.callImc(response[0].data.cnsCartList[0].deliveryAddress);
          } else {
            window.scrollTo(0, 0);
            this.setGlobalError(response[0].data.actionErrors);
            this.props.toggleSpinner(false);
          }
        })
        .catch((error) => {
          console.log(error);
          window.scrollTo(0, 0);
          this.setGlobalError([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleSpinner(false);
        });
    } else {
      window.location.replace("/labelInformation");
    }
  }

  callImc = (deliveryAddress) => {
    const countryCode = deliveryAddress.countryCode;
    const stateCode = deliveryAddress.stateCode;
    axios
      .post("/go/cnsrest/tlc/imc/hsCode?countryAbbv=" + countryCode)
      .then((response) => {
        this.hsTariffCheck(response, countryCode, stateCode);
      })
      .catch((error) => {
        console.log(error);
        this.hsTariffCheck(null, countryCode, stateCode);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  hsTariffCheck = (response, countryCode, stateCode) => {
    this.setState({
      focusOnTop: true,
      isLoaded: true,
      isHsTariffMandatory: response?.data?.hsCodeMandatory,
      isHsTariffRequired:
        (response?.data?.hsCodeRequired || stateCode === "AE") &&
        this.props.lookupCodes.tlc_classify_toggle &&
        this.props.lookupCodes.tlc_classify_toggle === "false",
      shipToCountryCode: countryCode
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // Scroll to the first section that has an error, and
    // focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.step6ErrorFound) {
        // Step 6 - Non-Delivery Options
        document.getElementById("non-delivery-options").scrollIntoView();
        if (this.errors["nonDelvOption"]) {
          document.getElementById("redirect-dropdown-a11y").focus();
        }
      } else if (this.step7ErrorFound) {
        // Step 7 - Package Information
        document.getElementById("package-information").scrollIntoView();
        if (this.errors["contents"]) {
          document.getElementById("contents-dropdown-a11y").focus();
        }
      } else if (this.step8ErrorFound) {
        // Step 8 - Item Information
        document.getElementById("item-information").scrollIntoView();
        if (this.errors["detailedDescription"]) {
          document.getElementById("item-detailed-description-a11y").focus();
        } else if (this.errors["itemValue"]) {
          document.getElementById("unitValueInput-a11y").focus();
        } else if (this.errors["quantity"]) {
          document.getElementById("itemQtyInput-a11y").focus();
        } else if (this.errors["itemWeight"]) {
          document.getElementById("itemWeightPoundsInput-a11y").focus();
        } else if (this.errors["hsTariffNbr"]) {
          document.getElementById("hs-tariff-input-a11y").focus();
        }
      } else if (this.step9ErrorFound) {
        // Step 9 - Export Information
        document.getElementById("export-information").scrollIntoView();
        if (this.errors["aesExemption"]) {
          document.getElementById("eelCodeInput-a11y").focus();
        } else if (this.errors["pfcCode"]) {
          document.getElementById("pfcCodeInput-a11y").focus();
        }
      } else if (this.step10ErrorFound) {
        // Step 10 - Additional Shipment Information
        document
          .getElementById("additional-shipment-information")
          .scrollIntoView();
        if (this.errors["phoneNbr"]) {
          document.getElementById("recipientPhoneNumberId-a11y").focus();
        } else if (this.errors["recipientEmailAddress"]) {
          document.getElementById("recipientEmailId-a11y").focus();
        } else if (this.errors["purposeOfShipment"]) {
          document.getElementById("purposeOfShipmentId-a11y").focus();
        } else if (this.errors["ultimateDestinationId"]) {
          document
            .getElementById("countryOfUltimateDestinationId-a11y")
            .focus();
        } else if (this.errors["invoiceDate"]) {
          document.getElementById("invoice-input-date-a11y").focus();
        } else if (this.errors["customerOrderNumber"]) {
          document.getElementById("customerOrderNumberId-a11y").focus();
        } else if (this.errors["paymentTerms"]) {
          document.getElementById("termsConditionsPaymentId-a11y").focus();
        } else if (this.errors["paymentDueDate"]) {
          document.getElementById("payment-input-date-a11y").focus();
        } else if (this.errors["taxId"]) {
          document.getElementById("taxId-a11y").focus();
        } else if (this.errors["deliveryTerms"]) {
          document.getElementById("termsConditionsDeliveryId-a11y").focus();
        } else if (this.errors["countryId"]) {
          document.getElementById("buyerInfoCountryId-a11y").focus();
        } else if (this.errors["firstName"]) {
          document.getElementById("buyerInfoFirstNameId-a11y").focus();
        } else if (this.errors["lastName"]) {
          document.getElementById("buyerInfoLastNameId-a11y").focus();
        } else if (this.errors["streetAddress"]) {
          document.getElementById("buyerInfoStreetAddressId-a11y").focus();
        } else if (this.errors["line1Addr"]) {
          document.getElementById("buyerInfoAddress1Id-a11y").focus();
        } else if (this.errors["cityName"]) {
          document.getElementById("buyerInfoCityId-a11y").focus();
        } else if (this.errors["stateId"]) {
          document.getElementById("buyerInfoStateId-a11y").focus();
        } else if (this.errors["zipCode"]) {
          document.getElementById("buyerInfoZipCodeId-a11y").focus();
        }
      }
      // Reset fields
      this.step6ErrorFound = false;
      this.step7ErrorFound = false;
      this.step8ErrorFound = false;
      this.step9ErrorFound = false;
      this.step10ErrorFound = false;
      this.setState({
        focusOnError: false
      });
    }
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("customs-info-top").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // Scroll to the top of the item information section, and
    // focus on the first field with an error
    if (this.state.focusOnLabelItemError) {
      document.getElementById("item-information").scrollIntoView();
      if (this.errors["detailedDescription"]) {
        document.getElementById("item-detailed-description-a11y").focus();
      } else if (this.errors["itemValue"]) {
        document.getElementById("unitValueInput-a11y").focus();
      } else if (this.errors["quantity"]) {
        document.getElementById("itemQtyInput-a11y").focus();
      } else if (this.errors["itemWeight"]) {
        document.getElementById("itemWeightPoundsInput-a11y").focus();
      } else if (this.errors["hsTariffNbr"]) {
        document.getElementById("hs-tariff-input-a11y").focus();
      }
      // Reset fields
      this.step6ErrorFound = false;
      this.step7ErrorFound = false;
      this.step8ErrorFound = false;
      this.step9ErrorFound = false;
      this.step10ErrorFound = false;
      this.setState({
        focusOnLabelItemError: false
      });
    }
    // Scroll to and focus on the input fields after choosing to edit an item
    if (this.state.focusOnItemEdit) {
      document.getElementById("item-information").scrollIntoView();
      document.getElementById("item-info-section-inputs").focus();
      this.setState({
        focusOnItemEdit: false
      });
    }
    // Call the landed cost API when all the required inputs have been loaded
    // (should only be called on page load and only if at least one item
    // is saved)
    if (prevState.readyToCallLandedCost !== this.state.readyToCallLandedCost) {
      this.toggleCallToLandedCostApi(true);
    }
    // Focus on the TLC email error, if present
    if (this.state.tlcFocusOnError) {
      if (this.state.emailValidationError) {
        document.getElementById("tlcEmail-a11y").focus();
      }
      this.setState({
        tlcFocusOnError: false
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize = () => {
    this.setState({
      isDesktop: window.innerWidth > 767
    });
  };

  refreshItems = (e) => {
    axios
      .get(
        "/go/cnsrest/fetchCustomsInfo?fetchPrefQueryString=" +
          this.props.location.state.cnsCartId
      )
      .then((response) => {
        this.setState((prevState) => {
          return {
            isLoaded: true,
            //fetchedCartList: response.data,
            fetchedLabelItemList: response.data.cnsCartList[0].labelItems,
            labelItem: {
              ...prevState.labelItem,
              itemDesc: "",
              itemQty: "",
              eccnNumber: "",
              labelItemId: "0",
              unitValue: "",
              unitWeightOz: "",
              hsTariffNbr: "",
              countryId: {
                ...prevState.labelItem.countryId,
                countryDbId: "0"
              },
              // Fields added for TLC (total landed cost)
              tlcCategory: "",
              tlcSubcategory: "",
              tlcItemDetail: "",
              tlcItemColor: "",
              tlcBrand: "",
              tlcUpcCode: "",
              tlcProductLength: "",
              tlcProductWidth: "",
              tlcProductHeight: ""
            },
            setWeightInOz: "",
            weightInPounds: "",
            inEditItem: false,
            shipFromCountryCode:
              response.data.cnsCartList[0].returnAddress.countryCode
          };
        });
        // Call the landed cost API again (as long as at least one label item
        // is present)
        if (
          response.data.cnsCartList[0].labelItems &&
          response.data.cnsCartList[0].labelItems.length > 0
        ) {
          this.toggleCallToLandedCostApi(true);
        }
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.setCanOmitHsTariff(false);
        this.props.toggleSpinner(false);
      });
  };

  reorderCountryList = (countryList) => {
    let unitedStates = [];
    // Add "Select" as an option
    unitedStates.push({
      countryDbId: 0,
      countryName: "Select"
    });
    // Move the United States to the top of the list
    let unitedStatesIndex = -1;
    for (let i in countryList) {
      if (countryList[i].countryDbId === 840) {
        unitedStates.push(countryList[i]);
        unitedStatesIndex = i;
        break;
      }
    }
    countryList.splice(unitedStatesIndex, 1);
    countryList = unitedStates.concat(countryList);
    // Extract and return only the needed fields
    let newCountryList = [];
    for (let i in countryList) {
      newCountryList.push({
        value: countryList[i].countryDbId,
        name: countryList[i].countryName,
        alpha2Abbrv: countryList[i].alpha2Abbrv
      });
    }
    return newCountryList;
  };

  showOcpi = () => {
    return (
      this.props.lookupCodes &&
      this.props.lookupCodes["cns.ocpi_enabled"] === "true" &&
      this.state.ocpiIndicator
    );
  };

  saveCustomsInfo = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.toggleSpinner(true);

    var dataToSend = {};
    let cnsCartList = [{}];

    cnsCartList[0].labelPkg = this.state.labelPkg;
    cnsCartList[0].labelCustDec = this.state.labelCustDec;
    cnsCartList[0].labelInd = this.state.labelInd;

    cnsCartList[0].deliveryAddress = this.state.recipientInfo;
    cnsCartList[0].labelGxg = JSON.parse(JSON.stringify(this.state.labelGxg));

    cnsCartList[0].labelGxg.commercialShipmentInd =
      this.state.commercialShipmentInd === "Y"
        ? this.state.commercialShipmentInd
        : this.showOcpi()
        ? "Y"
        : "N";
    cnsCartList[0].labelGxg.invoiceDate = moment(
      cnsCartList[0].labelGxg.invoiceDate
    ).format("YYYY-MM-DD");
    cnsCartList[0].labelGxg.paymentDueDate = moment(
      cnsCartList[0].labelGxg.paymentDueDate
    ).format("YYYY-MM-DD");

    if (this.state.differentBuyer) {
      dataToSend.buyerRecipientDifference = "Y";
      cnsCartList[0].buyerAddress = JSON.parse(
        JSON.stringify(this.state.buyerAddress)
      );
      if (this.isBuyerDomestic()) {
        cnsCartList[0].buyerAddress.line1Addr =
          cnsCartList[0].buyerAddress.streetAddress;
        cnsCartList[0].buyerAddress.line2Addr =
          cnsCartList[0].buyerAddress.aptSuiteOther;
        cnsCartList[0].buyerAddress.postalCode =
          cnsCartList[0].buyerAddress.zipCode;
      }
      delete cnsCartList[0].buyerAddress.streetAddress;
      delete cnsCartList[0].buyerAddress.aptSuiteOther;
      delete cnsCartList[0].buyerAddress.zipCode;
    } else {
      dataToSend.buyerRecipientDifference = "N";
    }

    dataToSend.labelItem = this.state.labelItem;
    dataToSend.labelItem["unitWeightOz"] =
      Number(
        this.state.weightInPounds === undefined ? 0 : this.state.weightInPounds
      ) *
        16 +
      Number(
        this.state.setWeightInOz === undefined ? 0 : this.state.setWeightInOz
      );
    dataToSend.cnsCartList = cnsCartList;
    dataToSend.cnsCartList[0].labelCustDec.eelCode =
      this.state.labelCustDec.eelCode || this.state.manualEelCode;
    axios
      .post(
        "/go/cnsrest/saveCustomsInfo?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          String(this.state.labelItem.labelItemId),
        JSON.stringify(dataToSend),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (!response.data.actionErrors) {
          this.props.history.push("/shippingCart");
        } else {
          window.scrollTo(0, 0);
          this.setGlobalError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.refreshItems();
        }
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.props.toggleSpinner(false);
      });
  };

  validate = (e) => {
    let errorsFound = false;
    let errorObject = {};
    // Step 6 - Non-Delivery Options
    if (
      !this.state.labelInd.nonDelvOption ||
      this.state.labelInd.nonDelvOption === "None"
    ) {
      errorObject["nonDelvOption"] = "Please select a non-delivery option.";
      errorsFound = true;
      this.step6ErrorFound = true;
    }
    // Step 7 - Package Information
    if (!this.state.labelCustDec.contents) {
      errorObject["contents"] = "Please select a content type.";
      errorsFound = true;
      this.step7ErrorFound = true;
    }
    // Step 8 - Item Information
    if (this.state.fetchedLabelItemList.length === 0) {
      if (!this.state.labelItem.itemDesc) {
        errorObject["detailedDescription"] =
          "Please enter an item description.";
        errorsFound = true;
        this.step8ErrorFound = true;
      }
      if (
        !this.state.labelItem.unitValue ||
        Number(this.state.labelItem.unitValue) < 0
      ) {
        errorObject["itemValue"] = "Please enter item value.";
        errorsFound = true;
        this.step8ErrorFound = true;
      }
      if (
        !this.state.labelItem.itemQty ||
        Number(this.state.labelItem.itemQty) < 0
      ) {
        errorObject["quantity"] = "Please enter quantity.";
        errorsFound = true;
        this.step8ErrorFound = true;
      }
      if (
        (!this.state.setWeightInOz && !this.state.weightInPounds) ||
        (Number(this.state.setWeightInOz) < 0 &&
          Number(this.state.weightInPounds) < 0)
      ) {
        errorObject["itemWeight"] = "Please enter package weight.";
        errorsFound = true;
        this.step8ErrorFound = true;
      }
      if (
        this.state.isHsTariffRequired &&
        (!this.canOmitHsTariff || this.state.isHsTariffMandatory) &&
        !this.state.labelItem.hsTariffNbr
      ) {
        errorObject["hsTariffNbr"] = "Please enter an HS Tariff Number.";
        errorsFound = true;
        this.step8ErrorFound = true;
      } else if (
        this.state.labelItem.hsTariffNbr &&
        (this.state.labelItem.hsTariffNbr.length < 6 ||
          this.state.labelItem.hsTariffNbr.length > 16)
      ) {
        errorObject["hsTariffNbr"] = "Please enter a valid HS Tariff Number.";
        errorsFound = true;
        this.step8ErrorFound = true;
      }
    }
    // Step 9 - Export Information
    if (
      this.state.labelInd.exportLicenseInd !== "Y" &&
      !this.state.labelCustDec.eelCode &&
      !this.state.manualEelCode
    ) {
      errorObject["aesExemption"] = "Please select an AES exemption.";
      errorsFound = true;
      this.step9ErrorFound = true;
    }
    if (
      this.state.labelInd.exportLicenseInd === "Y" &&
      !this.state.labelCustDec.pfcCode
    ) {
      errorObject["pfcCode"] = "Please enter an AES/ITN.";
      errorsFound = true;
      this.step9ErrorFound = true;
    }
    // Step 10 - Additional Shipment Information
    if (this.showOcpi()) {
      if (!this.state.recipientInfo.phoneNbr) {
        errorObject["phoneNbr"] = "Please enter a phone number.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.recipientInfo.emailAddress) {
        errorObject["recipientEmailAddress"] = "Please enter an email.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.purposeOfShipment) {
        errorObject["purposeOfShipment"] = "Please enter purpose of shipment.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (
        !this.state.labelGxg.ultimateDestinationId ||
        this.state.labelGxg.ultimateDestinationId === "0"
      ) {
        errorObject["ultimateDestinationId"] =
          "Please enter the country of ultimate destination.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.invoiceDate) {
        errorObject["invoiceDate"] = "Please select an invoice date.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.customerOrderNumber) {
        errorObject["customerOrderNumber"] =
          "Please enter a customer order number.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.paymentTerms) {
        errorObject["paymentTerms"] =
          "Please enter the terms and conditions of payment.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.paymentDueDate) {
        errorObject["paymentDueDate"] = "Please enter the payment due date.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.taxId) {
        errorObject["taxId"] = "Please enter a tax ID / VAT / EIN number.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (!this.state.labelGxg.deliveryTerms) {
        errorObject["deliveryTerms"] =
          "Please enter the terms and conditions of delivery.";
        errorsFound = true;
        this.step10ErrorFound = true;
      }
      if (this.state.differentBuyer) {
        if (
          !this.state.buyerAddress.countryId ||
          this.state.buyerAddress.countryId === "0"
        ) {
          errorObject["countryId"] = "Please select country.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (!this.state.buyerAddress.firstName) {
          errorObject["firstName"] = "Please enter a first name.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (!this.state.buyerAddress.lastName) {
          errorObject["lastName"] = "Please enter a last name.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (this.isBuyerDomestic() && !this.state.buyerAddress.streetAddress) {
          errorObject["streetAddress"] = "Provide a valid street address.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (!this.isBuyerDomestic() && !this.state.buyerAddress.line1Addr) {
          errorObject["line1Addr"] = "Provide a valid Address 1.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (!this.state.buyerAddress.cityName) {
          errorObject["cityName"] = "Please enter city.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (
          this.isBuyerDomestic() &&
          (!this.state.buyerAddress.stateId ||
            this.state.buyerAddress.stateId === "-1")
        ) {
          errorObject["stateId"] = "Please select a state.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
        if (this.isBuyerDomestic() && !this.state.buyerAddress.zipCode) {
          errorObject["zipCode"] = "Please enter ZIP Code™.";
          errorsFound = true;
          this.step10ErrorFound = true;
        }
      }
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  validateLabelItem = () => {
    let errorsFound = false;
    let errorObject = {};

    if (!this.state.labelItem.itemDesc) {
      errorObject["detailedDescription"] = "Please enter an item description.";
      errorsFound = true;
    }
    if (
      !this.state.labelItem.unitValue ||
      Number(this.state.labelItem.unitValue) < 0
    ) {
      errorObject["itemValue"] = "Please enter item value.";
      errorsFound = true;
    }
    if (
      !this.state.labelItem.itemQty ||
      Number(this.state.labelItem.itemQty) < 0
    ) {
      errorObject["quantity"] = "Please enter quantity.";
      errorsFound = true;
    }
    if (
      (!this.state.setWeightInOz && !this.state.weightInPounds) ||
      (Number(this.state.setWeightInOz) < 0 &&
        Number(this.state.weightInPounds) < 0)
    ) {
      errorObject["itemWeight"] = "Please enter package weight.";
      errorsFound = true;
    }
    if (
      this.state.isHsTariffRequired &&
      (!this.canOmitHsTariff || this.state.isHsTariffMandatory) &&
      !this.state.labelItem.hsTariffNbr
    ) {
      errorObject["hsTariffNbr"] = "Please enter an HS Tariff Number.";
      errorsFound = true;
    } else if (
      this.state.labelItem.hsTariffNbr &&
      (this.state.labelItem.hsTariffNbr.length < 6 ||
        this.state.labelItem.hsTariffNbr.length > 16)
    ) {
      errorObject["hsTariffNbr"] = "Please enter a valid HS Tariff Number.";
      errorsFound = true;
    }

    this.errors = errorObject;
    this.setInputErrors("errorObject")(errorObject);

    return errorsFound;
  };

  // Verify whether the item to be added is prohibited or restricted
  checkForProhibitions = (proceedIfAllowed, e) => {
    if (e) {
      e.preventDefault();
    }
    // Do not make this API call if initiated by the item section
    // and inputs are invalid
    if (!proceedIfAllowed && this.validateLabelItem()) {
      this.setState({
        focusOnLabelItemError: true
      });
      return;
    }
    // Do not make this API call if initiated when attempting to proceed
    // to the next page and inputs are invalid
    if (proceedIfAllowed && this.validate()) {
      this.setState({
        focusOnError: true
      });
      return;
    }
    // Do not make this API call if initiated when attempting to proceed
    // to the next page and there is already at least one item saved to
    // the cart (currently, any additional items can only be added from
    // the item table in step 8)
    if (proceedIfAllowed && this.state.fetchedLabelItemList.length > 0) {
      this.saveCustomsInfo();
      return;
    }
    // Clear existing label item errors
    if (this.state.errors.labelItemError.length > 0) {
      this.clearError("labelItemError");
    }
    // If the user is not shipping to a military destination
    // and either an HS tariff is required or the user provided one,
    // make the API call
    let stateId = this.state.fetchedCartList.cnsCartList[0].deliveryAddress
      .stateId;
    stateId = stateId ? String(stateId) : "-1";
    if (
      stateId !== "2" &&
      stateId !== "3" &&
      stateId !== "6" &&
      this.state.isHsTariffRequired &&
      this.state.labelItem.hsTariffNbr
    ) {
      this.props.toggleSpinner(true);
      axios
        .post("/go/cnsrest/tlc/restrictions", {
          items: [
            {
              country_of_origin: this.get2LetterCountryCode(
                this.state.labelItem.countryId?.countryDbId
              ),
              description: this.state.labelItem.itemDesc,
              hs_code: this.state.labelItem.hsTariffNbr
            }
          ],
          ship_from_country: this.state.shipFromCountryCode,
          ship_to_country: this.state.shipToCountryCode,
          restriction_type_threshold: "OBSERVATION"
        })
        .then((response) => {
          const prohibitionsRestrictions =
            response?.data?.items?.[0]?.item_restrictions || [];
          this.setState({
            prohibitionsRestrictions: prohibitionsRestrictions
          });
          if (prohibitionsRestrictions.length > 0) {
            this.handleProhibitedItemModal(true, proceedIfAllowed);
            this.props.toggleSpinner(false);
          } else {
            if (proceedIfAllowed) {
              this.saveCustomsInfo();
            } else {
              this.addLabelItem();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (proceedIfAllowed) {
            this.saveCustomsInfo();
          } else {
            this.addLabelItem();
          }
        });
    } else {
      if (proceedIfAllowed) {
        this.saveCustomsInfo();
      } else {
        this.addLabelItem();
      }
    }
  };

  // Get the 2-letter country code for the provided country ID
  get2LetterCountryCode = (countryDbId) => {
    let countryCode2Letter = null;
    for (let index in this.state.countryList) {
      if (countryDbId === this.state.countryList[index].value) {
        countryCode2Letter = this.state.countryList[index].alpha2Abbrv;
        break;
      }
    }
    return countryCode2Letter;
  };

  addLabelItem = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.toggleSpinner(true);
    // Modify label item fields before saving
    let labelItem = {};
    labelItem["labelItem"] = this.state.labelItem;
    labelItem["labelItem"]["unitWeightOz"] =
      Number(
        this.state.weightInPounds === undefined ? 0 : this.state.weightInPounds
      ) *
        16 +
      Number(
        this.state.setWeightInOz === undefined ? 0 : this.state.setWeightInOz
      );
    labelItem["labelItem"]["labelItemId"] = String(
      this.state.labelItem.labelItemId || ""
    );
    labelItem["labelItem"]["unitValue"] = String(
      this.state.labelItem.unitValue || ""
    );
    // Save the label item
    axios
      .post(
        "/go/cnsrest/saveLabelItem?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          String(this.state.labelItem.labelItemId),
        JSON.stringify(labelItem),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        if (
          (response.data.fieldErrors === undefined ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (response.data.actionErrors === undefined ||
            response.data.actionErrors.length === 0)
        ) {
          this.clearError("hsTariffNbr");
          this.refreshItems();
        } else {
          this.labelItemError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.props.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  cancelAddLabelItem = (e) => {
    e.preventDefault();
    this.setState({
      inEditItem: false,
      labelItem: {
        ...this.state.labelItem,
        itemDesc: "",
        itemQty: "",
        eccnNumber: "",
        labelItemId: "0",
        unitValue: "",
        unitWeightOz: "",
        hsTariffNbr: "",
        countryId: {
          ...this.state.labelItem.countryId,
          countryDbId: "0"
        },
        // Fields added for TLC (total landed cost)
        tlcCategory: "",
        tlcSubcategory: "",
        tlcItemDetail: "",
        tlcItemColor: "",
        tlcBrand: "",
        tlcUpcCode: "",
        tlcProductLength: "",
        tlcProductWidth: "",
        tlcProductHeight: ""
      },
      setWeightInOz: "",
      weightInPounds: ""
    });
    this.setCanOmitHsTariff(false);
  };

  changeItemInfo = (input) => (event) => {
    // If the input being changed is present directly in the item section
    // and not exclusive to the lookup HS tariff modal, reset the flag that
    // determines if an HS tariff is required
    if (
      input === "itemDesc" ||
      input === "itemQty" ||
      input === "unitValue" ||
      input === "weightInPounds" ||
      input === "setWeightInOz" ||
      input === "countryId"
    ) {
      this.setCanOmitHsTariff(false);
    }
    // The country of origin is stored within its own object
    if (input === "countryId") {
      this.setState({
        labelItem: {
          ...this.state.labelItem,
          countryId: {
            ...this.state.labelItem.countryId,

            countryDbId: event
          }
        }
      });
    } else {
      this.setState((prevState) => {
        // Check for valid inputs in the quantity, weight, value, and HS tariff
        // fields
        if (
          input === "itemQty" ||
          input === "weightInPounds" ||
          input === "setWeightInOz"
        ) {
          event = event.replaceAll(/\D/g, "");
        } else if (input === "unitValue" && !validation.currency.test(event)) {
          event = prevState.labelItem.unitValue;
        } else if (input === "hsTariffNbr") {
          if (event.replace(/[^0-9]/g, "").length <= 14) {
            event = event.replaceAll(/[^0-9.]/g, "");
          } else {
            event = prevState.labelItem.hsTariffNbr;
          }
        }
        if (input === "weightInPounds" || input === "setWeightInOz") {
          return {
            [input]: event
          };
        } else {
          return {
            labelItem: {
              ...prevState.labelItem,
              [input]: event
            }
          };
        }
      });
    }
  };

  // Update the HS Tariff # with the specified value
  updateHsTariff = (value) => {
    this.changeItemInfo("hsTariffNbr")(value);
    this.clearError("hsTariffNbr");
  };

  // Flag that determines whether an HS tariff that is initially required
  // can be omitted
  setCanOmitHsTariff = (value) => {
    this.canOmitHsTariff = value;
  };

  changeDelvOption = (input) => (event) => {
    this.setState({
      labelInd: {
        ...this.state.labelInd,
        [input]: event
      }
    });
  };

  changeLabelCustDec = (input) => (event) => {
    if (input === "contents") {
      this.setState({
        labelCustDec: {
          ...this.state.labelCustDec,
          [input]: event
        },
        labelPkg: {
          ...this.state.labelPkg,
          containerType: event
        }
      });
    } else if (input === "eelCode") {
      if (event === "exportLicense") {
        this.setState({
          labelInd: {
            ...this.state.labelInd,
            exportLicenseInd: "Y"
          },
          labelCustDec: {
            ...this.state.labelCustDec,
            pfcCode: ""
          },
          manualEelCode: ""
        });
      } else {
        this.setState({
          labelCustDec: {
            ...this.state.labelCustDec,
            [input]: event,
            pfcCode: ""
          },
          manualEelCode: ""
        });
      }
    } else if (input === "manualEelCode") {
      this.setState({
        [input]: event,
        labelCustDec: {
          ...this.state.labelCustDec,
          eelCode: "",
          pfcCode: ""
        }
      });
    } else {
      this.setState({
        labelCustDec: {
          ...this.state.labelCustDec,
          [input]: event
        }
      });
    }
  };

  changeRecipientInfo = (input) => (event) => {
    this.setState((prevState) => {
      return {
        recipientInfo: {
          ...prevState.recipientInfo,
          [input]: event
        }
      };
    });
  };

  changeAdditionalShipmentInfo = (input) => (event) => {
    this.setState((prevState) => {
      return {
        labelGxg: {
          ...prevState.labelGxg,
          [input]: event
        }
      };
    });
  };

  changeInvoiceDate = (notUsed) => (event) => {
    this.setState((prevState) => {
      return {
        labelGxg: {
          ...prevState.labelGxg,
          invoiceDate: event
        }
      };
    });
  };

  changePaymentDueDate = (notUsed) => (event) => {
    this.setState((prevState) => {
      return {
        labelGxg: {
          ...prevState.labelGxg,
          paymentDueDate: event
        }
      };
    });
  };

  changeBuyerInfo = (input) => (event) => {
    this.setState((prevState) => {
      return {
        buyerAddress: {
          ...prevState.buyerAddress,
          [input]: event
        }
      };
    });
  };

  setWeightInPounds = (e) => {
    this.changeItemInfo("weightInPounds")(e.target.value);
  };

  setWeightInOz = (e) => {
    this.changeItemInfo("setWeightInOz")(e.target.value);
  };

  //this whole weight mess needs to get cleaned up
  getPoundsAndOz = (e, totalWeightInOz, quantity) => {
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    return pounds + "lbs " + ounces + "oz";
  };

  getOunces = (totalWeightInOz) => {
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    if (ounces === 0) {
      return "";
    } else if (ounces === "NaN") {
      return "";
    } else {
      return "";
    }
  };

  getPounds = (totalWeightInOz) => {
    let pounds;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);
    } else {
      pounds = 0;
    }
    if (pounds === 0) {
      return "";
    } else {
      return pounds;
    }
  };

  setEditWeight = (totalWeightInOz) => {
    let pounds;
    let ounces;
    if (totalWeightInOz >= 16) {
      pounds = Math.floor(totalWeightInOz / 16);

      ounces = totalWeightInOz - pounds * 16;
    } else {
      pounds = 0;
      ounces = totalWeightInOz - pounds * 16;
    }
    this.setState({
      setWeightInOz: ounces === isNaN ? "0" : ounces,
      weightInPounds: pounds === isNaN ? "0" : pounds,
      unitWeightOz: totalWeightInOz.totalWeightInOz
    });
  };

  editLabelItem = (e, item) => {
    e.preventDefault();
    // Clear any existing errors or flags for the item fields
    this.clearError("detailedDescription");
    this.clearError("itemValue");
    this.clearError("quantity");
    this.clearError("itemWeight");
    this.clearError("hsTariffNbr");
    this.setCanOmitHsTariff(false);
    // Update the item fields
    this.setState((prevState) => {
      return {
        inEditItem: true,
        focusOnItemEdit: true,
        labelItem: {
          ...prevState.labelItem,
          itemDesc: item.itemDesc,
          itemQty: item.itemQty,
          eccnNumber: item.eccnNumber,
          labelItemId: item.labelItemId,
          unitValue: item.unitValue,
          unitWeightOz: this.setEditWeight(item.unitWeightOz),
          hsTariffNbr: item.hsTariffNbr ? item.hsTariffNbr : "",
          countryId: {
            ...prevState.labelItem.countryId,
            countryDbId: item.countryId ? item.countryId.countryDbId : "0"
          },
          // Fields added for TLC (total landed cost)
          tlcCategory: item.tlcCategory || "",
          tlcSubcategory: item.tlcSubcategory || "",
          tlcItemDetail: item.tlcItemDetail || "",
          tlcItemColor: item.tlcItemColor || "",
          tlcBrand: item.tlcBrand || "",
          tlcUpcCode: item.tlcUpcCode || "",
          tlcProductLength:
            item.tlcProductLength > 0 ? String(item.tlcProductLength) : "",
          tlcProductWidth:
            item.tlcProductWidth > 0 ? String(item.tlcProductWidth) : "",
          tlcProductHeight:
            item.tlcProductHeight > 0 ? String(item.tlcProductHeight) : ""
        }
      };
    });
  };

  requiredExportLicense = (e) => {
    const checked = e.currentTarget.checked;
    this.setState((prevState) => {
      return {
        labelInd: {
          ...prevState.labelInd,
          exportLicenseInd: checked ? "Y" : "N"
        },
        labelCustDec: {
          ...this.state.labelCustDec,
          eelCode: "",
          pfcCode: ""
        },
        manualEelCode: ""
      };
    });
  };

  deleteItem = (item) => {
    this.props.toggleSpinner(true);
    if (this.state.errors.labelItemError.length > 0) {
      this.clearError("labelItemError");
    }
    axios
      .post(
        "/go/cnsrest/deleteLabelItem?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          item.labelItemId,
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (
          (response.data.fieldErrors === undefined ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (response.data.actionErrors === undefined ||
            response.data.actionErrors.length === 0)
        ) {
          this.refreshItems();
        } else {
          this.labelItemError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.props.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  deleteAllItems = () => {
    this.props.toggleSpinner(true);
    if (this.state.errors.labelItemError.length > 0) {
      this.clearError("labelItemError");
    }
    axios
      .post(
        "/go/cnsrest/deleteLabelItem?cnsCartId=" +
          this.props.location.state.cnsCartId +
          "&labelItemId=" +
          this.getLabelItemIdList(),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        if (
          (response.data.fieldErrors === undefined ||
            Object.keys(response.data.fieldErrors).length === 0) &&
          (response.data.actionErrors === undefined ||
            response.data.actionErrors.length === 0)
        ) {
          this.refreshItems();
        } else {
          this.labelItemError(
            response.data.fieldErrors,
            response.data.actionErrors
          );
          this.props.toggleSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.toggleSpinner(false);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  // Add all label item IDs to a comma-separated string
  getLabelItemIdList = () => {
    let labelItemIdList = "";
    labelItemIdList += this.state.fetchedLabelItemList[0].labelItemId;
    for (let i = 1; i < this.state.fetchedLabelItemList.length; i++) {
      labelItemIdList += "," + this.state.fetchedLabelItemList[i].labelItemId;
    }
    return labelItemIdList;
  };

  setLabelItemsClickedMobile = (labelItemId) => {
    this.setState((prevState) => {
      let newArray = [...prevState.labelItemsClickedMobile];
      if (!newArray.includes(labelItemId)) {
        // Expand this item (add it to the array of items that were clicked)
        // since it was previously collapsed
        newArray.push(labelItemId);
      } else {
        // Collapse this item (remove it from the array of items that were
        // clicked) since it was previously expanded
        const indexToRemove = newArray.indexOf(labelItemId);
        newArray.splice(indexToRemove, 1);
      }
      return {
        labelItemsClickedMobile: newArray
      };
    });
  };

  getTotalPackageWeight = (e) => {
    let allItemWeight = this.state.fetchedLabelItemList.reduce(function (
      total,
      arr
    ) {
      return total + arr.unitWeightOz;
    },
    0);

    return this.getPoundsAndOz(1, allItemWeight);
  };

  getTotalPackageValue = (e) => {
    let allItemValue = this.state.fetchedLabelItemList.reduce(function (
      total,
      arr
    ) {
      return total + arr.unitValue;
    },
    0);

    return allItemValue.toFixed(2);
  };

  isBuyerDomesticInitially = (countryId) => {
    return countryId ? String(countryId) === "840" : false;
  };

  isBuyerDomestic = () => {
    return this.state.buyerAddress.countryId === "840";
  };

  showRestrictions = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        showRestrictions: true,
        showRestrictionsMobile: prevState.isDesktop
          ? true
          : !prevState.showRestrictionsMobile,
        showProhibitions: false,
        showProhibitionsMobile: false
      };
    });
  };

  showProhibitions = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        showRestrictions: false,
        showRestrictionsMobile: false,
        showProhibitions: true,
        showProhibitionsMobile: prevState.isDesktop
          ? true
          : !prevState.showProhibitionsMobile
      };
    });
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: errorMessage
        }
      });
    }
  };

  clearError = (fieldName) => {
    this.setState((prevState) => {
      return {
        errors: {
          ...prevState.errors,
          [fieldName]: fieldName === "labelItemError" ? [] : ""
        }
      };
    });
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  labelItemError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("labelItemError")(combinedErrors);
  };

  handleHsTariffCodeModal = (event, value) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      lookupHsTariffCodeModalToDisplay: value
    });
  };

  handleProhibitedItemModal = (value, proceedIfAllowed, event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      prohibitedRestrictedModalToDisplay: value,
      proceedIfAllowed: proceedIfAllowed
    });
  };

  checkLandedCost = () => {
    return (
      this.state.isHsTariffRequired &&
      this.state.fetchedCartList.cnsCartList[0].deliveryAddress.countryCode !==
        "US" &&
      this.state.fetchedLabelItemList.length > 0
    );
  };

  handleLandedCostEstimateModal = (event, value) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      displayLandedCostEstimateModal: value
    });
  };

  toggleCallToLandedCostApi = (value) => {
    this.setState({
      callLandedCostApi: value
    });
  };

  setLandedCostInputs = (landedCostInputs) => {
    this.setState({
      landedCostInputs: landedCostInputs
    });
  };

  setReadyToCallLandedCost = (value) => {
    this.setState({
      readyToCallLandedCost: value
    });
  };

  setTlcAmounts = (amounts) => {
    if (amounts) {
      const duties = !isNaN(amounts.duties)
        ? amounts.duties.toFixed(2)
        : "0.00";
      const fees = !isNaN(amounts.fees) ? amounts.fees.toFixed(2) : "0.00";
      const taxes = !isNaN(amounts.taxes) ? amounts.taxes.toFixed(2) : "0.00";
      this.setState({
        tlcAmounts: {
          duties: duties,
          fees: fees,
          taxes: taxes,
          sum: (Number(duties) + Number(fees) + Number(taxes)).toFixed(2)
        }
      });
    } else {
      this.setState({
        tlcAmounts: null
      });
    }
  };

  handleTlcEmail = (event) => {
    this.setState({
      tlcEmail: event.currentTarget.value
    });
  };

  handleTlcEmailSuccessful = (value) => {
    this.setState({
      emailSuccessful: value
    });
  };

  handleTlcEmailValidationError = (value) => {
    this.setState({
      emailValidationError: value
    });
  };

  handleTlcEmailSubmit = () => {
    this.handleTlcEmailSuccessful(null);
    if (this.state.tlcEmail) {
      this.handleTlcEmailValidationError("");
      this.props.toggleSpinner(true);
      axios
        .post("/go/cnsrest/tlc/email_data", {
          tlcEmail: this.state.tlcEmail,
          cnsCartId: this.props.location.state.cnsCartId
        })
        .then((response) => {
          if (response?.data === true) {
            this.handleTlcEmailSuccessful(true);
          } else {
            this.handleTlcEmailSuccessful(false);
          }
        })
        .catch((error) => {
          this.handleTlcEmailSuccessful(false);
          console.log(error);
        })
        .finally(() => {
          this.props.toggleSpinner(false);
        });
    } else {
      this.handleTlcEmailValidationError("Please enter an email address.");
      this.setState({
        tlcFocusOnError: true
      });
    }
  };

  renderNonDeliveryOptions = () => {
    return (
      <React.Fragment>
        <div className="step-header" id="non-delivery-options">
          <h2 className="normal step-eight-header">
            Step 6: <strong>Non-Delivery Options</strong>
          </h2>
          <div>
            <div className="row">
              <div className="col-12 col-md-11 col-lg-10 input-form">
                <div className="row">
                  <div className="col-12 col-md-6 form-group">
                    <label>Destination</label>
                    <p>
                      <strong>
                        {
                          this.state.fetchedCartList.cnsCartList[0]
                            .deliveryAddress.countryName
                        }
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      "col-12 col-md-6 col-lg-5 form-group required-field" +
                      (this.state.errors.nonDelvOption !== "" ? " error" : "")
                    }
                  >
                    <AssistiveError
                      inputId="redirect-dropdown"
                      errorMessage={this.state.errors.nonDelvOption}
                    />
                    <label htmlFor="redirect-dropdown">
                      *Non-Delivery Option
                    </label>
                    <select
                      className="form-control dropdown"
                      id="redirect-dropdown"
                      onChange={(e) => {
                        this.changeDelvOption("nonDelvOption")(e.target.value);
                        this.state.errors.nonDelvOption !== ""
                          ? this.clearError("nonDelvOption")
                          : this.clearError("");
                      }}
                      value={this.state.labelInd.nonDelvOption}
                    >
                      <option value="None">Select</option>
                      <option value="RETURN_TO_SENDER">Return to Sender</option>
                      <option value="ABANDON">Treat as Abandoned</option>
                    </select>
                    <span className="error-message">
                      {this.state.errors.nonDelvOption}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderPackageInformation = () => {
    return (
      <React.Fragment>
        <div className="step-header" id="package-information">
          <h2 className="normal">
            Step 7: <strong>Package Information</strong>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-11 col-lg-10 input-form">
            <form className="form-wrapper">
              <div className="row">
                <div className="col-12 form-group">
                  <p>
                    Provide as much information as possible and any relevant
                    information about the contents of your shipment.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-5 form-group">
                  <div className="row">
                    <div
                      className={
                        "col-12 form-group required-field" +
                        (this.state.errors.contents !== "" ? " error" : "")
                      }
                    >
                      <AssistiveError
                        inputId="contents-dropdown"
                        errorMessage={this.state.errors.contents}
                      />
                      <label htmlFor="contents-dropdown">*Contents</label>
                      <select
                        className="form-control dropdown"
                        id="contents-dropdown"
                        onChange={(e) => {
                          this.changeLabelCustDec("contents")(e.target.value);
                          this.state.errors.contents !== ""
                            ? this.clearError("contents")
                            : this.clearError("");
                        }}
                        value={this.state.labelCustDec.contents}
                      >
                        <option value="">Select</option>
                        <option value="DOCUMENTS">Documents</option>
                        <option value="GIFT">Gifts</option>
                        <option value="MERCHANDISE">Merchandise</option>
                        <option value="RETURN">Returned Goods</option>
                        <option value="SAMPLE">Commercial Sample</option>
                        <option value="HUMANITARIAN">
                          Humanitarian Donation
                        </option>
                        {/* <option value="DANGEROUSGOODS">Dangerous Goods</option> */}
                        <option value="OTHER">Other</option>
                      </select>
                      <span className="error-message">
                        {this.state.errors.contents}
                      </span>
                      {/* <p className="note-dangerous-goods">
                        NOTE: If your mailing contains a dangerous good,
                        including any item with a lithium battery, you must
                        choose the Dangerous Goods option.
                      </p> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="text_contents">
                        Contents Description{" "}
                        <ContentsDescPopover targetName="contentsDescIcon" />
                      </label>
                      <textarea
                        className="form-control textarea-counter extra-input-field"
                        id="text_contents"
                        name="text"
                        maxLength="25"
                        rows="5"
                        onChange={(e) =>
                          this.changeLabelCustDec("contentsDesc")(
                            e.target.value
                          )
                        }
                        value={this.state.labelCustDec.contentsDesc}
                      />
                      <span
                        className="pull-left label label-default"
                        id="count_message_contents"
                      >
                        {this.state.labelCustDec.contentsDesc.length > 0
                          ? this.state.labelCustDec.contentsDesc.length
                          : 0}{" "}
                        / 25
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="text_comments">
                        Comments <CommentsPopover targetName="commentsIcon" />
                      </label>
                      <textarea
                        className="form-control textarea-counter extra-input-field"
                        id="text_comments"
                        rows="5"
                        maxLength="100"
                        onChange={(e) =>
                          this.changeLabelCustDec("comments")(e.target.value)
                        }
                        value={this.state.labelCustDec.comments}
                      />
                      <span
                        className="pull-left label label-default"
                        id="count_message_comments"
                      >
                        {this.state.labelCustDec.comments.length > 0
                          ? this.state.labelCustDec.comments.length
                          : 0}{" "}
                        / 100
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  id="commercial-sender-section-step-7"
                  className="col-12 col-md-6 commercial-sender-section-wrapper"
                >
                  <div className="commercial-senders-info-box">
                    {!this.state.isHsTariffRequired && (
                      <p>
                        <strong>Commercial Senders Only</strong>
                      </p>
                    )}
                    <div className="row">
                      <div className="col-12 form-group">
                        <label htmlFor="license-number-input">
                          License Number{" "}
                          <LicenceNumberPopover targetName="licenseNumberIcon" />
                        </label>
                        <input
                          tabIndex="0"
                          id="license-number-input"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.changeLabelCustDec("licenseNbr")(
                              e.target.value
                            )
                          }
                          value={this.state.labelCustDec.licenseNbr}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 form-group">
                        <label htmlFor="certificate-number-input">
                          Certificate Number{" "}
                          <CertNumberPopover targetName="certNumberIcon" />
                        </label>
                        <input
                          tabIndex="0"
                          id="certificate-number-input"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.changeLabelCustDec("certificateNbr")(
                              e.target.value
                            )
                          }
                          value={this.state.labelCustDec.certificateNbr}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <InputFields
                        divClassList="col-12 form-group"
                        inputId="invoiceNbrId"
                        labelText="Invoice Number"
                        infoIcon={
                          <InvoiceNumberPopover targetName="invoiceNumberIcon" />
                        }
                        inputValue={this.state.labelCustDec.invoiceNbr || ""}
                        inputOnChange={(e) => {
                          this.changeLabelCustDec("invoiceNbr")(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderItemInformation = () => {
    return (
      <React.Fragment>
        <div className="row" id="item-information">
          <div className="col-12">
            <div className="step-header">
              <h2 className="normal">
                Step 8: <strong>Item Information</strong>
              </h2>
            </div>
            <p>
              Provide information about item(s) you are shipping. You must add
              at least 1 item and up to 30 items. Items with different values
              must be entered on different lines.
            </p>
          </div>
        </div>
        <div id="item-info-section-inputs" className="row" tabIndex="-1">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div
                  className={
                    "form-group enter-any-additional-instructions required-field" +
                    (this.state.errors.detailedDescription !== ""
                      ? " error"
                      : "")
                  }
                >
                  <div className="add-a-message">
                    <AssistiveError
                      inputId="item-detailed-description"
                      errorMessage={this.state.errors.detailedDescription}
                    />
                    <label
                      htmlFor="item-detailed-description"
                      className="inputLabel"
                    >
                      *Detailed Description{" "}
                      <DetailedDescPopover targetName="detailedDescIcon" />
                    </label>
                    <textarea
                      className="form-control form-controlInputLabel"
                      id="item-detailed-description"
                      rows="2"
                      maxLength={this.itemDescMaxLength}
                      onChange={(e) => {
                        this.changeItemInfo("itemDesc")(e.target.value);
                        this.state.errors.detailedDescription !== ""
                          ? this.clearError("detailedDescription")
                          : this.clearError("");
                      }}
                      value={this.state.labelItem.itemDesc}
                    />
                    <div id="detailed-description-counter">
                      {this.state.labelItem.itemDesc
                        ? this.state.labelItem.itemDesc.length
                        : 0}{" "}
                      / {this.itemDescMaxLength}
                    </div>
                  </div>
                  <span className="error-message">
                    {this.state.errors.detailedDescription}
                  </span>
                </div>
              </div>
            </div>
            <div className="row item-value-quantity-wrapper">
              <div
                className={
                  "col-12 form-group required-field item-value-wrapper" +
                  (this.state.errors.itemValue !== "" ? " error" : "")
                }
              >
                <AssistiveError
                  inputId="unitValueInput"
                  errorMessage={this.state.errors.itemValue}
                />
                <label htmlFor="unitValueInput">*Total Item Value</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    className="form-control form-controlTotalItemValue prepend"
                    id="unitValueInput"
                    tabIndex="0"
                    type="text"
                    value={this.state.labelItem.unitValue || ""}
                    onChange={(e) => {
                      this.changeItemInfo("unitValue")(e.target.value);
                      this.state.errors.itemValue !== ""
                        ? this.clearError("itemValue")
                        : this.clearError("");
                    }}
                  />
                </div>
                <span className="error-message">
                  {this.state.errors.itemValue}
                </span>
              </div>
            </div>
            <div className="row item-value-quantity-wrapper">
              <div
                className={
                  "col-12 form-group required-field quantity-wrapper" +
                  (this.state.errors.quantity !== "" ? " error" : "")
                }
              >
                <AssistiveError
                  inputId="itemQtyInput"
                  errorMessage={this.state.errors.quantity}
                />
                <label htmlFor="itemQtyInput">*Total Quantity</label>
                <input
                  tabIndex="0"
                  id="itemQtyInput"
                  type="number"
                  className="form-control form-controlItemQty"
                  value={this.state.labelItem.itemQty || ""}
                  onChange={(e) => {
                    this.changeItemInfo("itemQty")(e.target.value);
                    this.state.errors.quantity !== ""
                      ? this.clearError("quantity")
                      : this.clearError("");
                  }}
                />
                <span className="error-message">
                  {this.state.errors.quantity}
                </span>
              </div>
              {this.showOcpi() ? (
                <InputFields
                  divClassList="col-6 col-md-3 form-group"
                  inputId="eccnNumberId"
                  labelText="ECCN Number"
                  infoIcon={<EccnNumberPopover targetName="eccnNumberIcon" />}
                  inputValue={this.state.labelItem.eccnNumber || ""}
                  inputMaxLength="5"
                  inputOnChange={(e) => {
                    this.changeItemInfo("eccnNumber")(e.target.value);
                  }}
                />
              ) : null}
            </div>
            <div className="row">
              <div
                className={
                  "col-12 package-weight-wrapper form-group required-field form-groupPackageWeight" +
                  (this.state.errors.itemWeight !== "" ? " error" : "")
                }
              >
                <AssistiveError
                  inputId="itemWeightPoundsInput"
                  errorMessage={this.state.errors.itemWeight}
                />
                <label>
                  *Total Weight{" "}
                  <ItemPackageWeight targetName="weightInfoIcon" />
                </label>
                <div className="row package-weight-fields-wrapper">
                  <div className="col-6 form-group required-field">
                    <form className="form-inline package-weight-input">
                      <div className="package-weight-lbs-input">
                        <div className="input-group">
                          <input
                            className="dimensions-form form-control append"
                            id="itemWeightPoundsInput"
                            type="text"
                            placeholder="0"
                            aria-label=""
                            aria-describedby=""
                            onChange={(e) => {
                              this.setWeightInPounds(e);
                              this.state.errors.itemWeight !== ""
                                ? this.clearError("itemWeight")
                                : this.clearError("");
                            }}
                            value={
                              this.state.weightInPounds ||
                              this.state.weightInPounds !== ""
                                ? this.state.weightInPounds
                                : this.getPounds(
                                    this.state.labelItem.unitWeightOz
                                  )
                            }
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">lbs</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-6 package-oz-input form-group required-field">
                    <form className="form-inline package-weight-input">
                      <div className="package-lbs-input">
                        <div className="input-group">
                          <input
                            className="dimensions-form form-control append"
                            id="itemWeightOuncesInput"
                            type="text"
                            placeholder="0"
                            aria-label=""
                            aria-describedby=""
                            onChange={(e) => {
                              this.setWeightInOz(e);
                              this.state.errors.itemWeight !== ""
                                ? this.clearError("itemWeight")
                                : this.clearError("");
                            }}
                            value={
                              this.state.setWeightInOz ||
                              this.state.setWeightInOz !== ""
                                ? this.state.setWeightInOz
                                : this.getOunces(
                                    this.state.labelItem.unitWeightOz
                                  )
                            }
                          />

                          <div className="input-group-append">
                            <span className="input-group-text">oz</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <span className="error-message">
                  {this.state.errors.itemWeight}
                </span>
              </div>
            </div>
          </div>
          <div
            id="commercial-sender-section-step-8"
            className="col-12 col-lg-6 commercial-sender-section-wrapper"
          >
            <div
              className={
                "commercial-senders-info-box" +
                (this.state.isHsTariffRequired ? " hs-tariff-required" : "")
              }
            >
              {!this.state.isHsTariffRequired && (
                <p>
                  <strong>Commercial Senders Only </strong>
                </p>
              )}
              <div className="row">
                <div
                  className={
                    "col-12 form-group required-field" +
                    (this.state.errors.hsTariffNbr !== "" ? " error" : "")
                  }
                >
                  <AssistiveError
                    inputId="hs-tariff-input"
                    errorMessage={this.state.errors.hsTariffNbr}
                  />
                  <label htmlFor="hs-tariff-input">
                    {(this.state.isHsTariffRequired ? "* " : "") +
                      "HS Tariff Number"}{" "}
                    <HsTariffPopover targetName="hsTariffIcon" />
                  </label>
                  <input
                    tabIndex="0"
                    id="hs-tariff-input"
                    type="text"
                    className="form-control form-controlHSTariff"
                    maxLength="16"
                    value={this.state.labelItem.hsTariffNbr || ""}
                    onChange={(e) => {
                      this.changeItemInfo("hsTariffNbr")(e.target.value);
                      this.state.errors.hsTariffNbr !== ""
                        ? this.clearError("hsTariffNbr")
                        : this.clearError("");
                    }}
                  />
                  <span className="error-message">
                    {this.state.errors.hsTariffNbr}
                  </span>
                </div>
              </div>
              {this.state.isHsTariffRequired && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 col-xl-6 submit-hs-tariff-code-wrapper">
                      <button
                        className="btn btn-primary"
                        id="submit-hs-tariff-code-button"
                        type="submit"
                        onClick={(e) => this.handleHsTariffCodeModal(e, true)}
                      >
                        Lookup HS Tariff Code
                      </button>
                    </div>
                  </div>
                  <LookupHsTariffCode
                    isOpen={this.state.lookupHsTariffCodeModalToDisplay}
                    toggle={this.handleHsTariffCodeModal}
                    item={{
                      ...this.state.labelItem,
                      weightInPounds: this.state.weightInPounds,
                      setWeightInOz: this.state.setWeightInOz
                    }}
                    itemDescMaxLength={this.itemDescMaxLength}
                    handleItemEntry={this.changeItemInfo}
                    clearParentError={this.clearError}
                    updateHsTariff={this.updateHsTariff}
                    isHsTariffMandatory={this.state.isHsTariffMandatory}
                    setCanOmitHsTariff={this.setCanOmitHsTariff}
                    shipToCountryCode={this.state.shipToCountryCode}
                    toggleLoadingBar={this.props.toggleSpinner}
                    countryList={this.state.countryList}
                    lookupCodes={this.props.lookupCodes}
                    deliveryStateId={
                      this.state.fetchedCartList.cnsCartList[0].deliveryAddress
                        .stateId
                        ? String(
                            this.state.fetchedCartList.cnsCartList[0]
                              .deliveryAddress.stateId
                          )
                        : "-1"
                    }
                    checkForProhibitions={this.checkForProhibitions}
                  />
                </React.Fragment>
              )}
              <div className="row">
                <div className="col-12 form-group">
                  <label htmlFor="country-of-origin-dropdown">
                    Country of Origin{" "}
                    <CountryOfOriginPopover targetName="countryOfOriginIcon" />
                  </label>
                  <select
                    className="form-control dropdown form-controlCountry country-dropdown"
                    id="country-of-origin-dropdown"
                    onChange={(e) =>
                      this.changeItemInfo("countryId")(e.target.value)
                    }
                    value={
                      this.state.labelItem.countryId
                        ? this.state.labelItem.countryId.countryDbId
                        : "0"
                    }
                  >
                    {this.state.countryList.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 form-group total-package-weight-txt">
            <label>Total Package Weight</label>
            <p>
              <strong>
                [
                <span id="total-package-weight-lbs">
                  {this.getTotalPackageWeight()}
                </span>
                ]
              </strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 form-group total-weight-txt">
            <label>Total Weight</label>
            <p>
              <strong>
                [
                <span id="total-weight-lbs">
                  {this.getPoundsAndOz(
                    1,
                    this.state.fetchedCartList.cnsCartList[0].labelPkg
                      .weightOzQty
                  )}
                </span>
                ]
              </strong>
            </p>
          </div>
        </div>
        {!this.state.inEditItem === true ? (
          <div className="row">
            <div className="col-12 col-md-10">
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary button--white"
                  id="step8-add-item"
                  onClick={(e) => {
                    this.checkForProhibitions(false, e);
                  }}
                >
                  Add Item
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary button--white"
                  id="step8-update-item"
                  onClick={(e) => {
                    this.checkForProhibitions(false, e);
                  }}
                >
                  Update Item
                </button>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="button-container">
                <button
                  type="button"
                  className="btn-primary button--white"
                  id="step8-cancel-item"
                  onClick={this.cancelAddLabelItem}
                >
                  Cancel Item
                </button>
              </div>
            </div>
          </div>
        )}
        <ProhibitedRestrictedItem
          isOpen={this.state.prohibitedRestrictedModalToDisplay}
          toggle={this.handleProhibitedItemModal}
          prohibitionsRestrictions={this.state.prohibitionsRestrictions}
          handleAddButton={this.addLabelItem}
          handleContinueButton={this.saveCustomsInfo}
          proceedIfAllowed={this.state.proceedIfAllowed}
          lookupCodes={this.props.lookupCodes}
        />
        {this.state.fetchedLabelItemList.length > 0 ? (
          <React.Fragment>
            <div className="row">
              <div className="col-12 col-md-11 col-lg-10">
                <p className="customs-info-label">
                  <strong>Package Items</strong>
                </p>

                <table className="table package-items-table summary">
                  <thead className="items-table-header">
                    <tr className="d-none d-md-table-row">
                      <th scope="col">Item #</th>
                      <th scope="col">Description</th>
                      <th scope="col">HS Tariff #</th>
                      <th scope="col" className="country-td">
                        Country of Origin
                      </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Value</th>
                      <th scope="col">Weight</th>
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="step-8-full-table">
                    {this.state.fetchedLabelItemList.map((item, keys) => (
                      <tr
                        key={item.labelItemId}
                        className={
                          this.state.labelItemsClickedMobile.includes(
                            item.labelItemId
                          )
                            ? "package-items-tr-active"
                            : ""
                        }
                      >
                        <th scope="row" />
                        {this.state.isDesktop ||
                        !(item.hsTariffNbr || item.countryId) ? (
                          <td className="package-items-desc">
                            {item.itemDesc}
                          </td>
                        ) : (
                          <td className="package-items-desc-mobile">
                            <button
                              type="button"
                              className="package-items-desc button-link"
                              onClick={() =>
                                this.setLabelItemsClickedMobile(
                                  item.labelItemId
                                )
                              }
                            >
                              {item.itemDesc}
                            </button>
                          </td>
                        )}
                        <td className="package-items-hs">{item.hsTariffNbr}</td>
                        <td className="country-td">{item.countryOfOrigin}</td>
                        <td className="package-items-qty">{item.itemQty}</td>
                        <td className="package-items-value">
                          ${item.unitValue.toFixed(2)}
                        </td>
                        <td className="package-items-weight">
                          {this.getPoundsAndOz(
                            1,
                            item.unitWeightOz,
                            item.itemQty
                          )}
                        </td>
                        <td className="package-items-edit">
                          <button
                            type="button"
                            className="inline-link-edit button-link"
                            onClick={(e) => this.editLabelItem(e, item)}
                            aria-label="Edit this item"
                          >
                            <span>Edit</span>
                          </button>
                        </td>
                        <td className="package-items-remove">
                          <span>
                            {" "}
                            <DeleteLabelItem
                              buttonLabel={"Remove"}
                              deleteItem={this.deleteItem}
                              item={item}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row total-package-weight-box required-field">
              <div className="col-6 col-md-5">
                <DeleteAllLabelItems
                  buttonLabel={"Remove All"}
                  deleteAllItems={this.deleteAllItems}
                />
              </div>
              <div className="col-6 col-md-5 text-right">
                <p className="calc-package-weight-text">
                  Total Package Value:{" "}
                  <strong>${this.getTotalPackageValue()}</strong>
                </p>
                <p className="total-package-weight-text">
                  Total Package Weight: {this.getTotalPackageWeight()}
                </p>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <AssistiveGlobalError
          globalErrorId="label-item-error-section"
          globalErrorArray={this.state.errors.labelItemError}
        />
        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLandedCostInfo = () => {
    return (
      <React.Fragment>
        <div>
          <h2 className="normal">
            <strong>Calculated Landed Cost Estimate</strong>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-11 col-lg-10">
            <p id="tlc-estimate-text">
              Landed cost includes the estimated calculation of duties, taxes
              and other fees that are imposed for shipments crossing
              international borders by the importing country's government.
              <br />
              <br />
              Upon delivery, the destination country's government may require
              the recipient to pay the shipment's estimated duties, taxes, and
              fees based on their customs assessment of the item(s). The sender
              does NOT pay these landed costs at this time. Although USPS does
              not determine landed costs for the recipient, an estimate is
              below.
            </p>
            <div className="row tlc-estimate-table">
              <div className="col-12 col-md-9 col-lg-7">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="tlc-table-column">Duties</td>
                      {this.state.tlcAmounts?.duties && (
                        <td className="tlc-table-column tlc-table-column-cost">
                          ${this.state.tlcAmounts.duties}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="tlc-table-column">Fees</td>
                      {this.state.tlcAmounts?.fees && (
                        <td className="tlc-table-column tlc-table-column-cost">
                          ${this.state.tlcAmounts.fees}
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td className="tlc-table-column">Taxes</td>
                      {this.state.tlcAmounts?.taxes && (
                        <td className="tlc-table-column tlc-table-column-cost">
                          ${this.state.tlcAmounts.taxes}
                        </td>
                      )}
                    </tr>
                    <tr id="tlc-summary-row">
                      <td id="tlc-summary-text" className="tlc-table-column">
                        <h4>Estimated Duties, Taxes and Fees</h4>
                      </td>
                      <td className="tlc-table-column tlc-table-column-cost">
                        {this.state.tlcAmounts?.sum && (
                          <h4>${this.state.tlcAmounts.sum}</h4>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tlc-button-wrapper">
              <button
                className="inline-link button-link btn-tlc-link"
                onClick={(e) => {
                  this.handleLandedCostEstimateModal(e, true);
                }}
              >
                View the Landed Cost for each item.
              </button>
            </div>
            <h4 id="tlc-estimate-email-header">
              Email a copy of the Landed Cost Estimate
            </h4>
            <div className="row">
              <InputFields
                divClassList="col-12 col-md-6 col-lg-5"
                errorMessage={this.state.emailValidationError}
                includeAssistiveError={true}
                inputId="tlcEmail"
                inputClassList="form-control"
                inputType="text"
                labelText="Enter Email"
                inputValue={this.state.tlcEmail}
                inputOnChange={(e) => {
                  this.handleTlcEmailSuccessful(null);
                  this.handleTlcEmailValidationError("");
                  this.handleTlcEmail(e);
                }}
              />
              <div className="col-12 col-md-6 col-lg-7 button-wrapper tlc-button-wrapper">
                <div
                  id="tlc-email-button-container"
                  className="button-container"
                >
                  <button
                    className="btn-primary button--white"
                    id="submit-tlc-email-button"
                    type="submit"
                    onClick={(e) => this.handleTlcEmailSubmit(e)}
                  >
                    Send Estimate
                  </button>
                </div>
              </div>
            </div>
            {this.state.emailSuccessful ? (
              <p className="tlc-email-confirmation-text">
                Success: Email with the Landed Cost summary will be sent after
                your order is complete.
              </p>
            ) : this.state.emailSuccessful === false ? (
              <p className="error-message tlc-email-error">
                There was an error saving the email address.
              </p>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderExportInformation = () => {
    return (
      <React.Fragment>
        <div className="step-header" id="export-information">
          <h2 className="normal">
            Step 9: <strong>Export Information</strong>
          </h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-11 col-lg-10 input-form">
            <form className="form-wrapper">
              <div className="row">
                <div className="col-12 form-group">
                  <p>
                    International packages within certain categories accepted by
                    the Postal Service<sup>™</sup> and/or delivery company must
                    display an Internal Transaction Number (ITN), an AES
                    Downtown Citation if unable to procure an ITN, or an AES
                    Exemption.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group export-license-cb">
                  <label
                    htmlFor="export-license-checkbox"
                    className="checkbox-text vertical"
                  >
                    <input
                      type="checkbox"
                      id="export-license-checkbox"
                      onChange={this.requiredExportLicense}
                      checked={this.state.labelInd.exportLicenseInd === "Y"}
                    />
                    <span className="checkbox" />
                    <strong>Shipment requires an Export License.</strong>{" "}
                    <a
                      href="https://pe.usps.gov/text/Imm/immc5_007.htm"
                      role="button"
                      className="info-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="visuallyhidden">
                        Additional information on export licenses
                      </span>
                    </a>
                  </label>
                </div>
              </div>
              {this.state.labelInd.exportLicenseInd !== "Y" ? (
                <div id="aes-exemption-container">
                  <div className="row">
                    <div
                      className={
                        "col-12 col-md-6 col-lg-5 form-group required-field" +
                        (this.state.errors.aesExemption !== "" ? " error" : "")
                      }
                    >
                      <AssistiveError
                        inputId="eelCodeInput"
                        errorMessage={this.state.errors.aesExemption}
                      />
                      <label htmlFor="eelCodeInput">
                        *AES Exemption{" "}
                        <ChooseAESExemptionPopover targetName="AESExemption" />
                      </label>
                      <select
                        className="form-control dropdown"
                        id="eelCodeInput"
                        onChange={(e) => {
                          this.changeLabelCustDec("eelCode")(e.target.value);
                          this.state.errors.aesExemption !== ""
                            ? this.clearError("aesExemption")
                            : this.clearError("");
                        }}
                        value={this.state.labelCustDec.eelCode}
                      >
                        <option value="">Select</option>
                        {this.state.eelCodeOptions.map((eelCode) => (
                          <option
                            key={eelCode.value}
                            value={
                              eelCode.value !== "I have an export license"
                                ? eelCode.value
                                : "exportLicense"
                            }
                          >
                            {eelCode.value + eelCode.description}
                          </option>
                        ))}
                      </select>
                      <span className="error-message">
                        {this.state.errors.aesExemption}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className={
                        "col-12 col-md-6 col-lg-5 form-group required-field" +
                        (this.state.errors.aesExemption !== "" ? " error" : "")
                      }
                    >
                      <label htmlFor="manualEelCodeInput">
                        *For additional exemptions, call the U.S. Census Bureau
                        at 1-800-549-0595 then enter the AES Exemption below.
                      </label>
                      <input
                        tabIndex="0"
                        id="manualEelCodeInput"
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          this.changeLabelCustDec("manualEelCode")(
                            e.target.value
                          );
                          this.state.errors.aesExemption !== ""
                            ? this.clearError("aesExemption")
                            : this.clearError("");
                        }}
                        value={this.state.manualEelCode || ""}
                      />
                      <span className="error-message">
                        {this.state.errors.aesExemption}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div id="aes-itn-container">
                  <div className="row">
                    <div className="col-12 form-group">
                      <p>
                        Generate an Internal Transaction Number (ITN) or AES
                        Downtime Citation from the U.S. Census Bureau, or call
                        800-549-0595.
                      </p>
                      <a
                        href="https://ace.cbp.dhs.gov/"
                        className="inline-link right-chevron"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Generate AES/ITN
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 col-md-6 col-lg-5 form-group required-field" +
                        (this.state.errors.pfcCode !== "" ? " error" : "")
                      }
                    >
                      <AssistiveError
                        inputId="pfcCodeInput"
                        errorMessage={this.state.errors.pfcCode}
                      />
                      <label htmlFor="pfcCodeInput">
                        *AES/ITN{" "}
                        <AesExemptionITNPopover targetName="AES_ITN_INFO_ICON" />
                      </label>
                      <input
                        tabIndex="0"
                        id="pfcCodeInput"
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          this.changeLabelCustDec("pfcCode")(e.target.value);
                          this.state.errors.pfcCode !== ""
                            ? this.clearError("pfcCode")
                            : this.clearError("");
                        }}
                        value={this.state.labelCustDec.pfcCode}
                      />
                    </div>
                  </div>
                  <span className="error-message">
                    {this.state.errors.pfcCode}
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-12 horizontal-line-container">
            <hr className="horizontal-line" />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderAdditionalShipmentInformation = () => {
    if (this.showOcpi()) {
      return (
        <React.Fragment>
          <div className="step-header" id="additional-shipment-information">
            <h2 className="normal">
              Step 10: <strong>Additional Shipment Information</strong>
            </h2>
          </div>
          <div className="row">
            <div className="col-12 col-md-11 col-lg-10 input-form">
              <form className="form-wrapper">
                <div className="form-group">
                  <p>
                    Provide additional information about the recipient and
                    shipment.
                  </p>
                  <p>
                    <strong>
                      Please enter the recipient's phone number and email
                      address.
                    </strong>
                  </p>
                </div>
                <div className="row">
                  <InputFields
                    divClassList="col-6 col-md-5 form-group required-field"
                    errorMessage={this.state.errors.phoneNbr}
                    includeAssistiveError={true}
                    inputId="recipientPhoneNumberId"
                    labelText="*Recipient Phone Number"
                    inputValue={this.state.recipientInfo.phoneNbr || ""}
                    inputMaxLength="25"
                    inputOnChange={(e) => {
                      this.changeRecipientInfo("phoneNbr")(e.target.value);
                      this.state.errors.phoneNbr !== ""
                        ? this.clearError("phoneNbr")
                        : this.clearError("");
                    }}
                  />
                  <InputFields
                    divClassList="col-6 col-md-5 form-group required-field"
                    errorMessage={this.state.errors.recipientEmailAddress}
                    includeAssistiveError={true}
                    inputId="recipientEmailId"
                    labelText="*Recipient Email"
                    inputValue={this.state.recipientInfo.emailAddress || ""}
                    inputMaxLength="55"
                    inputOnChange={(e) => {
                      this.changeRecipientInfo("emailAddress")(e.target.value);
                      this.state.errors.recipientEmailAddress !== ""
                        ? this.clearError("recipientEmailAddress")
                        : this.clearError("");
                    }}
                  />
                </div>
                <div className="row">
                  <InputFields
                    divClassList="col-12 col-md-6 form-group required-field"
                    errorMessage={this.state.errors.purposeOfShipment}
                    includeAssistiveError={true}
                    inputId="purposeOfShipmentId"
                    labelText="*Purpose of shipment"
                    infoIcon={
                      <PurposeOfShipmentPopover targetName="purpose-of-shipment-popover-id" />
                    }
                    inputValue={this.state.labelGxg.purposeOfShipment || ""}
                    inputOnChange={(e) => {
                      this.changeAdditionalShipmentInfo("purposeOfShipment")(
                        e.target.value
                      );
                      this.state.errors.purposeOfShipment !== ""
                        ? this.clearError("purposeOfShipment")
                        : this.clearError("");
                    }}
                  />
                </div>
                <div className="row">
                  <InputFields
                    divClassList="col-12 col-md-6 form-group required-field"
                    errorMessage={this.state.errors.ultimateDestinationId}
                    includeAssistiveError={true}
                    inputId="countryOfUltimateDestinationId"
                    labelText="*Country of Ultimate Destination"
                    infoIcon={
                      <CountryUltimateDestinationPopover targetName="country-ultimate-destination-popover-id" />
                    }
                    elementType="select"
                    inputValue={
                      this.state.labelGxg.ultimateDestinationId || "0"
                    }
                    inputOnChange={(e) => {
                      this.changeAdditionalShipmentInfo(
                        "ultimateDestinationId"
                      )(e.target.value);
                      this.state.errors.ultimateDestinationId !== ""
                        ? this.clearError("ultimateDestinationId")
                        : this.clearError("");
                    }}
                    optionsList={this.state.countryList}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="row">
                      <AssistiveError
                        inputId="invoice-input-date"
                        errorMessage={this.state.errors.invoiceDate}
                      />
                      <div
                        className={
                          "col-12 required-field" +
                          (this.state.errors.invoiceDate !== "" ? " error" : "")
                        }
                        onFocus={() =>
                          this.state.errors.invoiceDate !== ""
                            ? this.clearError("invoiceDate")
                            : this.clearError("")
                        }
                      >
                        <label
                          htmlFor="invoice-input-date"
                          className="choose-shipping-date-label"
                        >
                          *Invoice Date
                        </label>
                        <DateRangeSelector
                          changeNonNestedFields={this.changeInvoiceDate}
                          currentShippingDates={[]}
                          isOutsideRange={(day) => moment().diff(day) > 5}
                          shippingDate={this.state.labelGxg.invoiceDate}
                        />
                        <span className="error-message">
                          {this.state.errors.invoiceDate}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <InputFields
                        divClassList="col-12 form-group required-field"
                        errorMessage={this.state.errors.customerOrderNumber}
                        includeAssistiveError={true}
                        inputId="customerOrderNumberId"
                        labelText="*Customer Order Number"
                        inputValue={
                          this.state.labelGxg.customerOrderNumber || ""
                        }
                        inputOnChange={(e) => {
                          this.changeAdditionalShipmentInfo(
                            "customerOrderNumber"
                          )(e.target.value);
                          this.state.errors.customerOrderNumber !== ""
                            ? this.clearError("customerOrderNumber")
                            : this.clearError("");
                        }}
                      />
                    </div>
                    <div className="row">
                      <InputFields
                        divClassList="col-12 form-group required-field"
                        errorMessage={this.state.errors.paymentTerms}
                        includeAssistiveError={true}
                        inputId="termsConditionsPaymentId"
                        labelText="*Terms and Conditions of Payment"
                        infoIcon={
                          <TermsConditionsPaymentPopover targetName="terms-conditions-payment-popover-id" />
                        }
                        elementType="textarea"
                        inputValue={this.state.labelGxg.paymentTerms || ""}
                        inputMaxLength="100"
                        inputOnChange={(e) => {
                          this.changeAdditionalShipmentInfo("paymentTerms")(
                            e.target.value
                          );
                          this.state.errors.paymentTerms !== ""
                            ? this.clearError("paymentTerms")
                            : this.clearError("");
                        }}
                      />
                    </div>
                    <div className="row">
                      <AssistiveError
                        inputId="payment-input-date"
                        errorMessage={this.state.errors.paymentDueDate}
                      />
                      <div
                        className={
                          "col-12 required-field" +
                          (this.state.errors.paymentDueDate !== ""
                            ? " error"
                            : "")
                        }
                        onFocus={() =>
                          this.state.errors.paymentDueDate !== ""
                            ? this.clearError("paymentDueDate")
                            : this.clearError("")
                        }
                      >
                        <label
                          htmlFor="invoice-payment-input-date"
                          className="choose-shipping-date-label"
                        >
                          *Payment Due Date
                        </label>
                        <DateRangeSelector
                          changeNonNestedFields={this.changePaymentDueDate}
                          currentShippingDates={[]}
                          isOutsideRange={(day) => moment().diff(day) > 5}
                          shippingDate={this.state.labelGxg.paymentDueDate}
                          inputId="input-date-2"
                        />
                        <span className="error-message">
                          {this.state.errors.paymentDueDate}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <InputFields
                        divClassList="col-12 form-group required-field"
                        errorMessage={this.state.errors.taxId}
                        includeAssistiveError={true}
                        inputId="taxId"
                        labelText="*Tax ID / VAT / EIN Number"
                        infoIcon={<TaxIdPopover targetName="taxIdInfoIcon" />}
                        inputValue={this.state.labelGxg.taxId || ""}
                        inputOnChange={(e) => {
                          this.changeAdditionalShipmentInfo("taxId")(
                            e.target.value
                          );
                          this.state.errors.taxId !== ""
                            ? this.clearError("taxId")
                            : this.clearError("");
                        }}
                      />
                    </div>
                    <div className="row">
                      <InputFields
                        divClassList="col-12 form-group required-field"
                        errorMessage={this.state.errors.deliveryTerms}
                        includeAssistiveError={true}
                        inputId="termsConditionsDeliveryId"
                        labelText="*Terms and Conditions of Delivery"
                        infoIcon={
                          <TermsConditionsDeliveryPopover
                            targetName="terms-conditions-delivery-popover-id"
                            forOcpi={true}
                          />
                        }
                        elementType="select"
                        inputValue={this.state.labelGxg.deliveryTerms || ""}
                        inputOnChange={(e) => {
                          this.changeAdditionalShipmentInfo("deliveryTerms")(
                            e.target.value
                          );
                          this.state.errors.deliveryTerms !== ""
                            ? this.clearError("deliveryTerms")
                            : this.clearError("");
                        }}
                        optionsList={[
                          {
                            value: "",
                            name: "Select"
                          },
                          {
                            value: "DAP",
                            name: "Delivery at Place"
                          }
                        ]}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 check-boxes">
                        <div className="checkbox-wrap">
                          <div className="row">
                            <div className="col-12">
                              <label
                                className="checkbox-component"
                                htmlFor="diff-buyer-info"
                              >
                                <input
                                  type="checkbox"
                                  id="diff-buyer-info"
                                  onChange={(e) =>
                                    this.setState({
                                      differentBuyer: e.currentTarget.checked
                                    })
                                  }
                                  checked={this.state.differentBuyer}
                                />
                                <span className="checkbox" />
                                The buyer information is different than the
                                recipient information
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.differentBuyer ? (
                    <div className="col-12 col-md-6">
                      <div className="commercial-senders-info-box">
                        <p>
                          <strong>Buyer Information</strong>
                        </p>
                        <InputFields
                          divClassList="col-12 form-group required-field"
                          errorMessage={this.state.errors.countryId}
                          includeAssistiveError={true}
                          inputId="buyerInfoCountryId"
                          labelText="*Country"
                          elementType="select"
                          inputValue={this.state.buyerAddress.countryId || ""}
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("countryId")(e.target.value);
                            this.state.errors.countryId !== ""
                              ? this.clearError("countryId")
                              : this.clearError("");
                          }}
                          optionsList={this.state.countryList}
                        />
                        <div id="buyer-info-first-middle-names" className="row">
                          <InputFields
                            divClassList="col-9 form-group required-field"
                            errorMessage={this.state.errors.firstName}
                            includeAssistiveError={true}
                            inputId="buyerInfoFirstNameId"
                            labelText="*First Name"
                            inputValue={this.state.buyerAddress.firstName || ""}
                            inputOnChange={(e) => {
                              this.changeBuyerInfo("firstName")(e.target.value);
                              this.state.errors.firstName !== ""
                                ? this.clearError("firstName")
                                : this.clearError("");
                            }}
                          />
                          <InputFields
                            divClassList="col-3 form-group"
                            inputId="buyerInfoMiddleInitId"
                            labelText="MI"
                            inputValue={
                              this.state.buyerAddress.middleInit || ""
                            }
                            inputMaxLength="1"
                            inputOnChange={(e) => {
                              this.changeBuyerInfo("middleInit")(
                                e.target.value
                              );
                            }}
                          />
                        </div>
                        <InputFields
                          divClassList="col-12 form-group required-field"
                          errorMessage={this.state.errors.lastName}
                          includeAssistiveError={true}
                          inputId="buyerInfoLastNameId"
                          labelText="*Last Name"
                          inputValue={this.state.buyerAddress.lastName || ""}
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("lastName")(e.target.value);
                            this.state.errors.lastName !== ""
                              ? this.clearError("lastName")
                              : this.clearError("");
                          }}
                        />
                        <InputFields
                          divClassList="col-12 form-group"
                          inputId="buyerInfoBusinessNameId"
                          labelText="Business Name"
                          inputValue={this.state.buyerAddress.companyName || ""}
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("companyName")(e.target.value);
                          }}
                        />
                        {this.isBuyerDomestic() ? (
                          <React.Fragment>
                            <InputFields
                              divClassList="col-12 form-group required-field"
                              errorMessage={this.state.errors.streetAddress}
                              includeAssistiveError={true}
                              inputId="buyerInfoStreetAddressId"
                              labelText="*Street Address"
                              inputValue={
                                this.state.buyerAddress.streetAddress || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("streetAddress")(
                                  e.target.value
                                );
                                this.state.errors.streetAddress !== ""
                                  ? this.clearError("streetAddress")
                                  : this.clearError("");
                              }}
                            />
                            <InputFields
                              divClassList="col-12 form-group"
                              inputId="buyerInfoAptSuiteOtherId"
                              labelText="Apt/Suite/Other"
                              inputValue={
                                this.state.buyerAddress.aptSuiteOther || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("aptSuiteOther")(
                                  e.target.value
                                );
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <InputFields
                              divClassList="col-12 form-group required-field"
                              errorMessage={this.state.errors.line1Addr}
                              includeAssistiveError={true}
                              inputId="buyerInfoAddress1Id"
                              labelText="*Address 1"
                              inputValue={
                                this.state.buyerAddress.line1Addr || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("line1Addr")(
                                  e.target.value
                                );
                                this.state.errors.line1Addr !== ""
                                  ? this.clearError("line1Addr")
                                  : this.clearError("");
                              }}
                            />
                            <InputFields
                              divClassList="col-12 form-group"
                              inputId="buyerInfoAddress2Id"
                              labelText="Address 2"
                              inputValue={
                                this.state.buyerAddress.line2Addr || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("line2Addr")(
                                  e.target.value
                                );
                              }}
                            />
                            <InputFields
                              divClassList="col-12 form-group"
                              inputId="buyerInfoProvinceId"
                              labelText="Province"
                              inputValue={
                                this.state.buyerAddress.province || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("province")(
                                  e.target.value
                                );
                              }}
                            />
                          </React.Fragment>
                        )}
                        <InputFields
                          divClassList="col-12 form-group required-field"
                          errorMessage={this.state.errors.cityName}
                          includeAssistiveError={true}
                          inputId="buyerInfoCityId"
                          labelText="*City"
                          inputValue={this.state.buyerAddress.cityName || ""}
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("cityName")(e.target.value);
                            this.state.errors.cityName !== ""
                              ? this.clearError("cityName")
                              : this.clearError("");
                          }}
                        />
                        {this.isBuyerDomestic() ? (
                          <React.Fragment>
                            <InputFields
                              divClassList="col-12 form-group required-field"
                              errorMessage={this.state.errors.stateId}
                              includeAssistiveError={true}
                              inputId="buyerInfoStateId"
                              labelText="*State"
                              elementType="select"
                              inputValue={this.state.buyerAddress.stateId || ""}
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("stateId")(e.target.value);
                                this.state.errors.stateId !== ""
                                  ? this.clearError("stateId")
                                  : this.clearError("");
                              }}
                              optionsList={StatesTerritoriesList}
                            />
                            <InputFields
                              divClassList="col-12 form-group required-field"
                              errorMessage={this.state.errors.zipCode}
                              includeAssistiveError={true}
                              inputId="buyerInfoZipCodeId"
                              labelText="*ZIP Code™"
                              inputValue={this.state.buyerAddress.zipCode || ""}
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("zipCode")(e.target.value);
                                this.state.errors.zipCode !== ""
                                  ? this.clearError("zipCode")
                                  : this.clearError("");
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <InputFields
                              divClassList="col-12 form-group"
                              inputId="buyerInfoPostalCodeId"
                              labelText="Postal Code"
                              inputValue={
                                this.state.buyerAddress.postalCode || ""
                              }
                              inputOnChange={(e) => {
                                this.changeBuyerInfo("postalCode")(
                                  e.target.value
                                );
                              }}
                            />{" "}
                          </React.Fragment>
                        )}
                        {this.isBuyerDomestic() &&
                        this.state.buyerAddress.stateId === "49" ? (
                          <InputFields
                            divClassList="col-12 form-group"
                            inputId="buyerInfoUrbanizationCodeId"
                            labelText="Urbanization Code"
                            infoIcon={
                              <UrbanizationCodesPopover targetName="urbanization-codes-popover-id" />
                            }
                            inputValue={
                              this.state.buyerAddress.urbanizationCode || ""
                            }
                            inputOnChange={(e) => {
                              this.changeBuyerInfo("urbanizationCode")(
                                e.target.value
                              );
                            }}
                          />
                        ) : null}
                        <InputFields
                          divClassList="col-12 form-group"
                          inputId="buyerInfoEmail"
                          labelText="Buyer Email"
                          inputValue={
                            this.state.buyerAddress.emailAddress || ""
                          }
                          inputMaxLength="55"
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("emailAddress")(
                              e.target.value
                            );
                          }}
                        />
                        <InputFields
                          divClassList="col-12 form-group"
                          inputId="buyerInfoPhone"
                          labelText="Buyer Phone"
                          inputValue={this.state.buyerAddress.phoneNbr || ""}
                          inputMaxLength="25"
                          inputOnChange={(e) => {
                            this.changeBuyerInfo("phoneNbr")(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-12 horizontal-line-container">
              <hr className="horizontal-line" />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  renderRestrictionsProhibitions = () => {
    return (
      <div className="row">
        <div className="col-12 col-md-11 col-lg-10 vdrawers">
          <div className="row">
            <div className="col-12">
              <h2>Please read about shipping restrictions and prohibitions.</h2>
            </div>
          </div>
          <div className="row">
            <div
              id="no-padding-mobile-restrictions"
              className="col-12 col-md-4 col-lg-3 v-tab"
            >
              <div
                className={
                  "shipping-restrictions" +
                  (this.state.showRestrictions
                    ? " active" +
                      (this.state.showRestrictionsMobile
                        ? " mobile-active"
                        : " mobile-inactive")
                    : "")
                }
              >
                <button
                  type="button"
                  className="button-link"
                  onClick={(e) => this.showRestrictions(e)}
                >
                  <h3>Restrictions</h3>
                </button>
                {this.state.showRestrictionsMobile ? (
                  <div className="mobile-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.fetchedCartList.cnsCartList[0]
                          .restrictions
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className={
                  "shipping-restrictions" +
                  (this.state.showProhibitions
                    ? " active" +
                      (this.state.showProhibitionsMobile
                        ? " mobile-active"
                        : " mobile-inactive")
                    : "")
                }
              >
                <button
                  type="button"
                  className="button-link"
                  onClick={(e) => this.showProhibitions(e)}
                >
                  <h3>Prohibitions</h3>
                </button>
                {this.state.showProhibitionsMobile ? (
                  <div className="mobile-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.fetchedCartList.cnsCartList[0]
                          .prohibitions
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-none col-md-8 col-lg-9 d-md-flex v-tab-content">
              {this.state.showRestrictions ? (
                <div
                  className="active"
                  dangerouslySetInnerHTML={{
                    __html: this.state.fetchedCartList.cnsCartList[0]
                      .restrictions
                  }}
                />
              ) : (
                <div
                  className="active"
                  dangerouslySetInnerHTML={{
                    __html: this.state.fetchedCartList.cnsCartList[0]
                      .prohibitions
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div
            className="Step_Eight_Container"
            id="customs-info-top"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <h2 className="d-md-none responsive-header">Create a Label</h2>
              <p>*indicates a required field</p>

              {this.renderNonDeliveryOptions()}
              {this.renderPackageInformation()}
              {this.renderItemInformation()}
              {this.checkLandedCost() && (
                <React.Fragment>
                  {this.state.tlcAmounts && this.renderLandedCostInfo()}
                  <LandedCostEstimate
                    isOpen={this.state.displayLandedCostEstimateModal}
                    toggle={this.handleLandedCostEstimateModal}
                    callLandedCostApi={this.state.callLandedCostApi}
                    toggleCallToLandedCostApi={this.toggleCallToLandedCostApi}
                    items={this.state.fetchedLabelItemList}
                    returnAddressCountryCode={this.state.shipFromCountryCode}
                    deliveryAddress={
                      this.state.fetchedCartList.cnsCartList[0].deliveryAddress
                    }
                    serviceInfo={{
                      amount: this.state.fetchedCartList.cnsCartList[0]
                        .labelCost.ttlShipCostAmt,
                      productClass: this.state.fetchedCartList.cnsCartList[0]
                        .productId.productClass
                    }}
                    landedCostInputs={this.state.landedCostInputs}
                    setLandedCostInputs={this.setLandedCostInputs}
                    setReadyToCallLandedCost={this.setReadyToCallLandedCost}
                    tlcAmounts={this.state.tlcAmounts}
                    setTlcAmounts={this.setTlcAmounts}
                    getPoundsAndOz={this.getPoundsAndOz}
                    cnsCart={this.state.fetchedCartList.cnsCartList[0]}
                    toggleLoadingBar={this.props.toggleSpinner}
                    get2LetterCountryCode={this.get2LetterCountryCode}
                  />
                </React.Fragment>
              )}
              {this.renderExportInformation()}
              {this.renderAdditionalShipmentInformation()}
              {this.renderRestrictionsProhibitions()}
              <div className="row">
                <div className="col-12 button-wrapper">
                  <div className="button-container">
                    <a
                      href="/shippingCart"
                      role="button"
                      className="btn-primary"
                      onClick={(e) => {
                        this.checkForProhibitions(true, e);
                      }}
                    >
                      Continue
                    </a>
                  </div>
                  <div className="button-container">
                    <a
                      href="/labelInformation"
                      className="btn-primary button--white"
                      onClick={this.editLabel}
                    >
                      Edit Label
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(CustomsDeclaration);
