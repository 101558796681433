import React from "react";
import "../../click-n-ship.css";
import "../../bootstrap.min.css";
import "../../calendar.css";
import "../../default-styles.css";
import axios from "axios";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DetailTermMeaning from "../Popovers/DetailTermMeaning";
import ShippingHistMoreActions from "../Popovers/ShippingHistMoreActions";
import PrintPostOfficeModal from "../Modals/PrintPostOfficeModal";
import RequestARefundModal from "../Modals/RequestARefundModal";
import CancelARefundModal from "../Modals/CancelARefundModal";
import NeedHelp from "../Popovers/NeedHelp";
import AssistiveGlobalError from "../Subcomponents/AssistiveGlobalError";
import update from "immutability-helper";
import validation from "../../utils/validation";
import ProductWithServices from "../Subcomponents/ProductWithServices";
import AdditionalDropoffInfo from "../Subcomponents/DropoffAdditionalInfo/AdditionalDropoffInfo";
class LabelDetails extends React.Component {
  constructor(props) {
    super(props);

    document.title = this.props.pageTitle;

    this.state = {
      isLoaded: false,
      responseError: "",
      errors: {
        globalError: []
      }
    };
  }

  toggle = () => {
    this.setState((prevState) => {
      return {
        displayPrintPostOffice: !prevState.displayPrintPostOffice
      };
    });
  };

  componentDidMount() {
    this.props.toggleSpinner(true);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderItemId
    ) {
      this.orderItemId = this.props.location.state.orderItemId;
      this.fetchOrderItemInfo();
    } else if (window.location.search.includes("orderItemId")) {
      const splitOrderItemId = window.location.search.split("=");
      this.orderItemId = splitOrderItemId[1];
      this.fetchOrderItemInfo();
    } else {
      this.backToShippingHistory();
    }
  }

  componentDidUpdate() {
    // Focus on the top element on the page after loading
    if (this.state.focusOnTop && this.state.isLoaded) {
      document.getElementById("view-label-container").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: false
      });
    }
    // Focus on the label broker message after a successful API call
    if (this.state.focusOnLabelBroker && this.state.labelBrokerId) {
      document.getElementById("labelBrokerSuccessGroup").focus();
      this.setState({
        focusOnLabelBroker: false
      });
    }
  }

  fetchOrderItemInfo = (focusOnLabelBroker) => {
    axios
      .get(
        "/go/cnsrest/fetchOrderItemInfo?fetchPrefQueryString=" +
          this.orderItemId
      )
      .then((response) => {
        this.setState({
          isLoaded: true,
          focusOnTop: true,
          fetchedLabelDetail: this.formatResponse(response.data),
          emailAddress:
            (response.data.item &&
              response.data.item.order &&
              response.data.item.order.profileEmailText) ||
            "",
          labelBrokerId: response.data.labelDataId,
          // Focus on the label broker message
          // (only passed to this function after clicking the Send Code button)
          focusOnLabelBroker: focusOnLabelBroker || false
        });
        this.trackingUrl = this.getTrackingUrl(response.data.labelActions);
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      })
      .finally(() => {
        this.props.toggleSpinner(false);
        this.setState({
          displayPrintPostOffice: false
        });
      });
  };

  formatResponse = (response) => {
    if (
      response.item &&
      response.item.packageCustomsDetails !== undefined &&
      response.labelActions
    ) {
      for (var i = 0; i < response.labelActions.length; i++) {
        if (response.labelActions[i].name === "Print at the Post Office") {
          response.labelActions.splice(i, 1);
          break;
        }
      }
    }
    if (
      response.item.orderItemInd.commercialInd === "Y" ||
      response.item.orderItemInd.commercialInd === "P" ||
      response.item.orderItemInd.commercialInd === "M" ||
      response.item.orderItemInd.commercialInd === "E"
    ) {
      response.item.productId.productClassDesc =
        response.item.productId.productClassDesc + " - Commercial Base";
    }
    return response;
  };

  setGlobalError = (fieldErrors, actionErrors) => {
    let flattenFieldErrors = Object.values(fieldErrors);

    let combinedErrors = flattenFieldErrors.concat(actionErrors);

    this.setInputErrors("globalError")(combinedErrors);
  };

  setInputErrors = (field) => (errorMessage) => {
    if (field === "errorObject") {
      let newState = update(this.state, {
        errors: { $merge: { ...errorMessage } }
      });

      this.setState(newState);
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  handleEmailAddress = (e) => {
    this.setState({
      emailAddress: e.currentTarget.value
    });
  };

  isValidEmail = () => {
    if (!validation.email.test(this.state.emailAddress)) {
      return false;
    }
    return true;
  };

  sendCode = () => {
    this.props.toggleSpinner(true);
    // Clear label broker error from response
    if (this.state.responseError) {
      this.setState({
        responseError: ""
      });
    }
    // Call the endpoint (requires Form Data rather than a Request Payload)
    const dataToSend =
      "orderItemIds=" +
      this.state.fetchedLabelDetail.item.orderItemId +
      "&labelBrokerEmail=" +
      this.state.emailAddress;
    axios
      .post("/go/cnsrest/labelbroker/provider", dataToSend, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then((response) => {
        if (response.data.responseCode === "200") {
          this.setState({
            labelBrokerId: response.data.labelId
          });
          this.fetchOrderItemInfo(true);
        } else {
          this.setState({
            responseError: response.data.responseMessage || ""
          });
        }
      })
      .catch((error) => {
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
        this.setState({
          displayPrintPostOffice: false
        });
      })
      .finally(() => {
        this.props.toggleSpinner(false);
      });
  };

  getTrackingUrl = (labelActions) => {
    for (let i in labelActions) {
      if (labelActions[i].name && labelActions[i].name.includes("Tracking")) {
        return labelActions[i].url;
      }
    }
    return null;
  };

  backToShippingHistory = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push("/shippingHistory");
  };

  orderNumberToShippingHistory = (e, orderId) => {
    e.preventDefault();
    this.props.history.push("/shippingHistory", { orderId: orderId });
  };

  requestARefund = (e, item) => {
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/requestRefund", {
        orderItemIds: this.state.fetchedLabelDetail.item.orderItemId
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  cancelARefund = (e, item) => {
    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/cancelRefund", {
        orderItemIds: this.state.fetchedLabelDetail.item.orderItemId
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        console.log(error);
        window.scrollTo(0, 0);
        this.setGlobalError(["We're sorry. An unexpected error has occurred."]);
      });
  };

  printRePrint = (e, name) => {
    let saveOrPrint = name === "Save PDF" ? "true" : "false";
    let url =
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/go/Secure/LabelDownloadServlet?orderItemIds=" +
      this.state.fetchedLabelDetail.item.orderItemId +
      "&fileForSave=" +
      saveOrPrint +
      "&prtNoInstructions=true" +
      "&manifestNumber=";

    var hiddenFrame = document.createElement("iframe");
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      hiddenFrame.onload = function () {};
      //hiddenFrame.src = iframeHtml;
      hiddenFrame.src = url;
      hiddenFrame.id = "pdfFrame";
      hiddenFrame.name = "pdfFrameName";
      hiddenFrame.style = "visibility: hidden";
      document.body.appendChild(hiddenFrame);
    } else {
      hiddenFrame.onload = function () {
        // invokes the auto-print functionality on the client by calling print on the iframe window
        this.contentWindow.focus();
        this.contentWindow.print();
      };
      //hiddenFrame.src = iframeHtml;
      hiddenFrame.src = url;
      hiddenFrame.style = "visibility: hidden";
      document.body.appendChild(hiddenFrame);
    }
  };

  addCommercialBase = () => {
    if (
      this.state.fetchedLabelDetail.item.orderItemInd.commercialInd === "Y" ||
      this.state.fetchedLabelDetail.item.orderItemInd.commercialInd === "P" ||
      this.state.fetchedLabelDetail.item.orderItemInd.commercialInd === "M" ||
      this.state.fetchedLabelDetail.item.orderItemInd.commercialInd === "E"
    ) {
      if (
        this.state.fetchedLabelDetail.item.detailedServiceDescription.includes(
          "<br/>"
        ) > -1
      ) {
        let splitBR = this.state.fetchedLabelDetail.item.detailedServiceDescription.split(
          "<br/>"
        );
        splitBR[1] === undefined
          ? (splitBR[1] = "")
          : (splitBR[1] = splitBR[1]);
        return splitBR[0] + " - Commercial Base <br/>" + splitBR[1];
      } else {
        return (
          this.state.fetchedLabelDetail.item.detailedServiceDescription +
          " - Commercial Base"
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <AssistiveGlobalError
          globalErrorArray={this.state.errors.globalError}
          styling={{ paddingLeft: "15px" }}
        />
        {this.state.isLoaded ? (
          <div
            className="View_Label_Container"
            id="view-label-container"
            tabIndex="-1"
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 label-details-wrapper">
                  <a
                    href="/shippingHistory"
                    className="inline-link left-chevron"
                    onClick={this.backToShippingHistory}
                    style={{ cursor: "pointer" }}
                  >
                    Back to Shipping History
                  </a>
                  {this.state.fetchedLabelDetail.item.cnsCartTypeId._name ===
                    "POSTAGE_DUE" ||
                  this.state.fetchedLabelDetail.item.cnsCartTypeId._name ===
                    "POSTAGE_DUE_SHORT_PAID" ? (
                    <h3 className="label-details-header">Postage Due</h3>
                  ) : (
                    <React.Fragment />
                  )}
                  <h3 className="label-details-header">
                    Label #{" "}
                    <a href={this.trackingUrl}>
                      {this.state.fetchedLabelDetail.item.trackingNbr}
                    </a>
                  </h3>
                  {this.state.fetchedLabelDetail.item.cnsCartTypeId._name ===
                    "POSTAGE_DUE" ||
                  this.state.fetchedLabelDetail.item.cnsCartTypeId._name ===
                    "POSTAGE_DUE_SHORT_PAID" ? (
                    <a
                      href={
                        "/labelDetails?orderItemId=" +
                        this.state.fetchedLabelDetail.item.postageDueChildId
                      }
                      className="inline-link right-chevron btn btn-link"
                      style={{ cursor: "pointer", paddingLeft: "0px" }}
                    >
                      View Original Order
                    </a>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="row">
                    <h3 className="component-header">
                      Terms{" "}
                      <DetailTermMeaning targetName="detailTermMeaningIcon" />
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-12">
                      <div className="form-group list-items">
                        <p className="column-header">Acceptance Cutoff</p>
                        {this.state.fetchedLabelDetail.item
                          .acceptanceCutoffTime ? (
                          <div>
                            <p className="view-label-info">
                              {
                                this.state.fetchedLabelDetail.item.acceptanceCutoffTime.split(
                                  " "
                                )[0]
                              }
                            </p>
                            <p className="view-label-info">
                              {
                                this.state.fetchedLabelDetail.item.acceptanceCutoffTime.split(
                                  " "
                                )[1]
                              }{" "}
                              {
                                this.state.fetchedLabelDetail.item.acceptanceCutoffTime.split(
                                  " "
                                )[2]
                              }
                            </p>
                          </div>
                        ) : (
                          <p className="view-label-info">No Data</p>
                        )}
                      </div>
                      <div className="form-group">
                        <p>Acceptance Time</p>
                        <p className="view-label-info">
                          {this.state.fetchedLabelDetail.item
                            .actualAcceptanceTime
                            ? this.state.fetchedLabelDetail.item
                                .actualAcceptanceTime
                            : "No Data"}
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-md-12">
                      <div className="form-group list-items">
                        {this.state.fetchedLabelDetail.item.productId
                          .productClass === "PRI" ? (
                          <p className="column-header">Expected Date</p>
                        ) : (
                          <p className="column-header">Scheduled Date</p>
                        )}
                        {this.state.fetchedLabelDetail.item.deliveryDate !==
                        undefined ? (
                          <div>
                            <p className="view-label-info">
                              {
                                this.state.fetchedLabelDetail.item.deliveryDate.split(
                                  " "
                                )[0]
                              }
                            </p>

                            <p className="view-label-info">
                              {
                                this.state.fetchedLabelDetail.item.deliveryDate.split(
                                  " "
                                )[1]
                              }{" "}
                              {
                                this.state.fetchedLabelDetail.item.deliveryDate.split(
                                  " "
                                )[2]
                              }
                            </p>
                          </div>
                        ) : (
                          <p className="view-label-info">No Data</p>
                        )}
                      </div>
                      <div className="form-group list-items">
                        <p className="column-header">Delivery Status</p>
                        <p className="view-label-info">
                          {
                            this.state.fetchedLabelDetail.item
                              .deliveryStatusDisplay
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="row">
                    <h3 className="component-header">Details</h3>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <p>Account Number</p>
                      <p className="view-label-info">
                        {this.props.userData.userId}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 addOverflowWrap">
                      <div className="form-group list-items">
                        <p className="column-header">Return Address</p>
                        <p className="view-label-info">
                          {
                            this.state.fetchedLabelDetail.item.shipFrom
                              .fullOrCompanyName
                          }
                        </p>
                        <p>
                          {
                            this.state.fetchedLabelDetail.item.shipFrom
                              .line1Addr
                          }
                        </p>
                        <p>
                          {this.state.fetchedLabelDetail.item.shipFromCityName},{" "}
                          {this.state.fetchedLabelDetail.item.shipFromStateCode}{" "}
                          {
                            this.state.fetchedLabelDetail.item
                              .shipFromPostalCode
                          }
                        </p>
                        <p>
                          {
                            this.state.fetchedLabelDetail.item.shipFrom
                              .emailAddress
                          }
                        </p>
                      </div>
                      <div className="form-group list-items">
                        <p className="column-header">Delivery Address</p>
                        <p className="view-label-info">
                          {
                            this.state.fetchedLabelDetail.item.shipTo
                              .fullOrCompanyName
                          }
                        </p>
                        <p>
                          {this.state.fetchedLabelDetail.item.shipTo.line1Addr}
                        </p>
                        <p>
                          {this.state.fetchedLabelDetail.item.shipTo.cityName},{" "}
                          {this.state.fetchedLabelDetail.item.shipTo.stateCode}
                        </p>
                        {this.state.fetchedLabelDetail.item
                          .orderItemDeliveryAddr.countryId !== 840 ? (
                          <p>
                            {
                              this.state.fetchedLabelDetail.item
                                .orderItemDeliveryAddr.countryName
                            }
                          </p>
                        ) : (
                          <React.Fragment />
                        )}
                        <p>
                          {this.state.fetchedLabelDetail.item.shipTo.zip5
                            ? this.state.fetchedLabelDetail.item.shipTo.zip5 +
                              (this.state.fetchedLabelDetail.item.shipTo
                                .zipPlus4
                                ? "-" +
                                  this.state.fetchedLabelDetail.item.shipTo
                                    .zipPlus4
                                : "")
                            : ""}
                        </p>
                        <p>
                          {
                            this.state.fetchedLabelDetail.item.shipTo
                              .emailAddress
                          }
                        </p>
                        {this.state.fetchedLabelDetail.item.parcelSelectType ? (
                          <div
                            style={{
                              marginTop: "20px",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "15px",
                                fontWeight: "bold"
                              }}
                            >
                              USPS Connect™ Local Drop-Off Location:{" "}
                              <span style={{ color: "#333366" }}>
                                {
                                  this.state.fetchedLabelDetail.item
                                    .orderItemDropOff.locationName
                                }
                              </span>
                            </p>

                            <p>
                              You must drop off all packages created for the
                              drop-off location selected (listed above and on
                              the
                            </p>
                            <p>
                              bottom of each of your USPS Connect™ Local labels)
                              at the same time.
                            </p>
                            <br />
                            <AdditionalDropoffInfo
                              locationName={
                                this.state.fetchedLabelDetail.item
                                  .orderItemDropOff.stateCode
                              }
                              physicalState={
                                this.state.fetchedLabelDetail.item
                                  .orderItemDropOff.locationName
                              }
                            />
                          </div>
                        ) : (
                          <React.Fragment />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group list-items">
                        <p className="column-header">Package</p>
                        <p className="view-label-info">
                          Ship Date:{" "}
                          {moment(
                            this.state.fetchedLabelDetail.item.shippingDate
                          ).format("MM/DD/YYYY")}
                        </p>
                        <p>
                          From:{" "}
                          {
                            this.state.fetchedLabelDetail.item
                              .shipFromPostalCode
                          }
                        </p>
                      </div>
                      <div className="form-group list-items">
                        <p className="column-header">Service Type</p>
                        {this.state.fetchedLabelDetail.item.productId &&
                        this.state.fetchedLabelDetail.item.productId
                          .productId >= 504 &&
                        this.state.fetchedLabelDetail.item.productId
                          .productId <= 508 ? (
                          <ProductWithServices
                            product={
                              this.state.fetchedLabelDetail.item.productId
                            }
                            orderItemPmtDetails={
                              this.state.fetchedLabelDetail.item
                                .orderItemPmtDetails
                            }
                            includePrices={false}
                            rowClassList="view-label-info"
                          />
                        ) : (
                          <p
                            className="view-label-info"
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.fetchedLabelDetail.item
                                  .detailedServiceDescription &&
                                !this.state.fetchedLabelDetail.item.detailedServiceDescription.includes(
                                  "null"
                                ) &&
                                this.state.fetchedLabelDetail.item.orderItemInd
                                  .commercialInd === "N"
                                  ? this.state.fetchedLabelDetail.item.detailedServiceDescription.replace(
                                      "&trade;",
                                      "™"
                                    )
                                  : this.state.fetchedLabelDetail.item
                                      .detailedServiceDescription &&
                                    !this.state.fetchedLabelDetail.item.detailedServiceDescription.includes(
                                      "null"
                                    ) &&
                                    this.state.fetchedLabelDetail.item
                                      .orderItemInd.commercialInd === "Y"
                                  ? this.addCommercialBase()
                                  : // Revert to product class (ex. for FCPS)
                                    this.state.fetchedLabelDetail.item
                                      .productId &&
                                    this.state.fetchedLabelDetail.item.productId
                                      .productClassDesc
                            }}
                          />
                        )}

                        {this.state.fetchedLabelDetail.item.purgeByDate !=
                          null &&
                        this.state.fetchedLabelDetail.item
                          .extendRetentionPurchasedCode != null ? (
                          <React.Fragment>
                            <p className="view-label-info">USPS Tracking</p>
                            <p className="view-label-info">
                              Tracking available until{" "}
                              {
                                this.state.fetchedLabelDetail.item
                                  .purgeByDateFormatted
                              }
                            </p>{" "}
                          </React.Fragment>
                        ) : (
                          <React.Fragment />
                        )}
                        {this.state.fetchedLabelDetail.item.orderItemInd
                          .stealthPostageInd === "Y" && (
                          <p className="view-label-info">Hide Postage Price</p>
                        )}
                        {this.state.fetchedLabelDetail.item.hazmatType !==
                          undefined && (
                          <p className="view-label-info">HAZMAT</p>
                        )}
                      </div>
                    </div>
                    {this.state.labelBrokerId ? (
                      <React.Fragment>
                        <div id="labelBrokerSuccessGroup" tabIndex="-1">
                          <br />
                          <p>Print at Post Office Option Selected:</p>
                          <p>
                            <strong>
                              Label Broker ID:{" "}
                              <span>{this.state.labelBrokerId}</span>
                            </strong>
                          </p>
                          <p>
                            Your label can now be printed at your local Post
                            Office&trade; by scanning the code received by email
                            or by bringing your Label Broker ID to the retail
                            counter. You may also print this label from your own
                            printer by selecting "Print/Re-Print" from Label
                            Actions.
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 horizontal-line-container">
                      <hr className="horizontal-line" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3 order-2 order-md-1">
                  <div className="row">
                    <h3 className="component-header">
                      More Actions{" "}
                      <ShippingHistMoreActions
                        targetName="shippingHistMoreActionsIcon"
                        showConnectSuppliesStatus={
                          this.state.fetchedLabelDetail.item.productId &&
                          (this.state.fetchedLabelDetail.item.productId
                            .productClass === "CL" ||
                            this.state.fetchedLabelDetail.item.productId
                              .productClass === "CLM")
                        }
                      />
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      {this.state.fetchedLabelDetail.labelActions.map(
                        (item) => (
                          <React.Fragment key={item.name}>
                            {item.name === "Ship Again" && this.state.fetchedLabelDetail.item.product.productCode === "GXG" ? null :
                            item.name !== "Print at the Post Office" &&
                            item.name !== "Print/Re-Print" &&
                            item.name !== "Save PDF" ? (
                              <p>
                                <a
                                  href={item.url}
                                  className="inline-link right-chevron"
                                >
                                  {item.name}
                                </a>
                              </p>
                            ) : item.name === "Print/Re-Print" ||
                              item.name === "Save PDF" ? (
                              <p>
                                <button
                                  style={{ padding: "0px" }}
                                  className="inline-link right-chevron button-link"
                                  onClick={(e) =>
                                    this.printRePrint(e, item.name)
                                  }
                                >
                                  {item.name}
                                </button>
                              </p>
                            ) : item.name === "Print at the Post Office" &&
                              !this.state.labelBrokerId ? (
                              <p>
                                {" "}
                                <PrintPostOfficeModal
                                  buttonLabel={item.name}
                                  displayPrintPostOffice={
                                    this.state.displayPrintPostOffice
                                  }
                                  toggle={this.toggle}
                                  emailAddress={this.state.emailAddress}
                                  handleEmailAddress={this.handleEmailAddress}
                                  isValidEmail={this.isValidEmail}
                                  sendCode={this.sendCode}
                                  responseError={this.state.responseError}
                                />
                              </p>
                            ) : (
                              <React.Fragment />
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <h3 className="component-header">
                      Need Help?{" "}
                      <NeedHelp
                        targetName={"needHelpIcon"}
                        loyaltyInfo={
                          this.props.loyaltyUserInfo != null
                            ? this.props.loyaltyUserInfo.userEnrolled
                            : false
                        }
                      />
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <p>
                        <a
                          href="https://www.usps.com/help/claims.htm"
                          className="inline-link right-chevron"
                        >
                          File an insurance claim
                        </a>
                      </p>
                      {this.state.fetchedLabelDetail.labelHelp.map((item) =>
                        item.name === "Request A Refund" ? (
                          <p key={item.name}>
                            <RequestARefundModal
                              requestARefund={this.requestARefund}
                              item={item}
                              buttonName={"Request A Refund"}
                              buttonType={
                                "inline-link right-chevron button-link"
                              }
                            />
                          </p>
                        ) : item.name === "Request A Service Refund" ? (
                          <p key={item.name}>
                            <a
                              href={item.url}
                              className="inline-link right-chevron"
                            >
                              {item.name}
                            </a>
                          </p>
                        ) : item.name === "Cancel Refund" ? (
                          <p key={item.name}>
                            <CancelARefundModal
                              cancelARefund={this.cancelARefund}
                              item={item}
                              buttonName={"Cancel A Refund"}
                              buttonType={
                                "inline-link right-chevron button-link"
                              }
                            />
                          </p>
                        ) /*: item.name === "Loyalty Activity History" ? (
                          <p key={item.name}>
                            <a
                              href={
                                this.props.lookupCodes["cns.loyalty_portal"] +
                                "activity-history/"
                              }
                              className="inline-link right-chevron"
                            >
                              {item.name}
                            </a>
                          </p>
                        ) */: (
                          <React.Fragment />
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7 order-1 order-md-2">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 col-md-12 form-group">
                          <p>Order Number</p>
                          <p className="view-label-info">
                            <a
                              className="inline-link secondary"
                              href="/shippingHistory"
                              onClick={(e) =>
                                this.orderNumberToShippingHistory(
                                  e,
                                  this.state.fetchedLabelDetail.item.orderId
                                )
                              }
                            >
                              {this.state.fetchedLabelDetail.item.orderId}
                            </a>
                          </p>
                        </div>
                        <div className="col-6 col-md-12 form-group">
                          <p>Transaction Type</p>
                          {this.state.fetchedLabelDetail.item.cnsCartTypeId
                            ._name !== "POSTAGE_DUE" &&
                          this.state.fetchedLabelDetail.item.cnsCartTypeId
                            ._name !== "POSTAGE_DUE_SHORT_PAID" ? (
                            <p className="view-label-info">
                              {" "}
                              {/* this.state.fetchedLabelDetail.item
                                  .orderItemPmtDetails[0].transactionDetailType */}
                              LABEL
                            </p>
                          ) : (
                            <p className="view-label-info">Postage Due</p>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-12 form-group">
                          <p>Payment Method</p>
                          <p className="view-label-info">
                            {" "}
                            {
                              this.state.fetchedLabelDetail.item.order
                                .paymentTypeDesc
                            }
                          </p>
                        </div>
                        <div className="col-6 col-md-12 form-group">
                          <p>Payment Status</p>
                          <p className="view-label-info">
                            {
                              this.state.fetchedLabelDetail.item
                                .statusDescription
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 service-type-table">
                      <div className="row service-type-table-header">
                        <div className="col-8">
                          <p>
                            <strong>Service Type</strong>
                          </p>
                        </div>
                        <div className="col-4 text-right">
                          <p>
                            <strong>Price</strong>
                          </p>
                        </div>
                      </div>

                      <React.Fragment>
                        {/* START OF FIRST LINE */}
                        <div className="row">
                          <div className="col-8">
                            <p>
                              {this.state.fetchedLabelDetail.item.productId.productClassDesc
                                .replace("&reg;", "®")
                                .replace("&trade;", "™")}
                            </p>
                          </div>
                          <div className="col-4 text-right">
                            {
                              // If there is no product description
                              (!this.state.fetchedLabelDetail.item.productId
                                .productDesc ||
                                // If the service type is FCPS
                                this.state.fetchedLabelDetail.item.productId.productClassDesc
                                  .replace("&reg;", "")
                                  .replace("®", "")
                                  .replace("&trade;", "")
                                  .replace("™", "") ===
                                  "First-Class Package Service - Retail" ||
                                // If the service type is FCPIS
                                this.state.fetchedLabelDetail.item.productId.productClassDesc
                                  .replace("&reg;", "")
                                  .replace("®", "")
                                  .replace("&trade;", "")
                                  .replace("™", "") ===
                                  "First-Class Package International Service") &&
                              // If pricing info is available
                              this.state.fetchedLabelDetail.item
                                .orderItemPmtDetails &&
                              this.state.fetchedLabelDetail.item
                                .orderItemPmtDetails[0] ? (
                                <p>
                                  $
                                  {this.state.fetchedLabelDetail.item.orderItemPmtDetails[0].skuAmt.toFixed(
                                    2
                                  )}
                                </p>
                              ) : null
                            }
                          </div>
                        </div>
                        {/* START OF SECOND LINE */}
                        {this.state.fetchedLabelDetail.item.orderItemPmtDetails.map(
                          (item, index) => (
                            <React.Fragment key={item.orderItemPmtDetailId}>
                              <div className="row">
                                <div className="col-8">
                                  {
                                    // If the service type is not FCPS
                                    this.state.fetchedLabelDetail.item.productId.productClassDesc
                                      .replace("&reg;", "")
                                      .replace("®", "")
                                      .replace("&trade;", "")
                                      .replace("™", "") !==
                                      "First-Class Package Service - Retail" &&
                                    // If the service type is not FCPIS
                                    this.state.fetchedLabelDetail.item.productId.productClassDesc
                                      .replace("&reg;", "")
                                      .replace("®", "")
                                      .replace("&trade;", "")
                                      .replace("™", "") !==
                                      "First-Class Package International Service" ? (
                                      <p>{item.transactionDetailSubtype}</p>
                                    ) : null
                                  }
                                </div>
                                {
                                  // Indices after 0 are extra services and
                                  // should always have their prices shown
                                  index > 0 ||
                                  // If index 0 is an extra service
                                  // (i.e. not a product description),
                                  // the price should also be shown here;
                                  // if there is a product description,
                                  // this will be at index 0 and
                                  // the price should be displayed on the line
                                  // above instead of here
                                  (index === 0 &&
                                    this.state.fetchedLabelDetail.item.productId
                                      .productDesc &&
                                    // If the service type is not FCPS
                                    this.state.fetchedLabelDetail.item.productId.productClassDesc
                                      .replace("&reg;", "")
                                      .replace("®", "")
                                      .replace("&trade;", "")
                                      .replace("™", "") !==
                                      "First-Class Package Service - Retail" &&
                                    // If the service type is not FCPIS
                                    this.state.fetchedLabelDetail.item.productId.productClassDesc
                                      .replace("&reg;", "")
                                      .replace("®", "")
                                      .replace("&trade;", "")
                                      .replace("™", "") !==
                                      "First-Class Package International Service") ? (
                                    <div className="col-4 text-right">
                                      {
                                        //item is a label and it has partial loyalty applied
                                        //in future maybe use orderItemCosts
                                        item.transactionDetailType ===
                                          "LABEL" &&
                                        this.state.fetchedLabelDetail.item
                                          .orderItemCost.loyaltyAmt &&
                                        this.state.fetchedLabelDetail.item
                                          .orderItemCost.postageAmt > 0 ? (
                                          <p>
                                            $
                                            {Number(
                                              item.skuAmt +
                                                this.state.fetchedLabelDetail
                                                  .item.orderItemCost.loyaltyAmt
                                            ).toFixed(2)}
                                          </p>
                                        ) : //item is a label and has all loyalty applied
                                        item.transactionDetailType ===
                                            "LABEL" &&
                                          this.state.fetchedLabelDetail.item
                                            .orderItemCost.loyaltyAmt &&
                                          this.state.fetchedLabelDetail.item
                                            .orderItemCost.postageAmt === 0 ? (
                                          <p>
                                            $
                                            {Number(
                                              this.state.fetchedLabelDetail.item
                                                .orderItemCost.loyaltyAmt
                                            ).toFixed(2)}
                                          </p>
                                        ) : (
                                          //item is normal with no loyalty use sku amount
                                          <p>${item.skuAmt.toFixed(2)}</p>
                                        )
                                      }
                                    </div>
                                  ) : null
                                }
                              </div>
                            </React.Fragment>
                          )
                        )}
                      </React.Fragment>
                      {this.state.fetchedLabelDetail.item.orderItemCost
                        .loyaltyAmt ? (
                        <div className="row">
                          <div className="col-8">
                            {" "}
                            <p className="">Loyalty Applied Credit:</p>
                          </div>
                          <div className="col-4 text-right">
                            <p>
                              - $
                              {this.state.fetchedLabelDetail.item.orderItemCost
                                .loyaltyAmt
                                ? this.state.fetchedLabelDetail.item.orderItemCost.loyaltyAmt.toFixed(
                                    2
                                  )
                                : 0}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}

                      <div className="row service-type-table-footer">
                        <div className="col-8">
                          <p>
                            <strong>Label Total</strong>
                          </p>
                        </div>
                        <div className="col-4 text-right">
                          <p>
                            <strong>
                              {" "}
                              $
                              {this.state.fetchedLabelDetail.item.orderItemCost.ttlShipCostAmt.toFixed(
                                2
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>

                      {this.state.fetchedLabelDetail.totalAppliedLoyalty ? (
                        <div>
                          <div className="row" style={{ marginTop: "15px" }}>
                            <div className="col-8">
                              {" "}
                              <p className="" style={{ fontWeight: "bold" }}>
                                Subtotal (
                                {
                                  this.state.fetchedLabelDetail.item.order
                                    .orderItemCnt
                                }
                                {this.state.fetchedLabelDetail.item.order
                                  .orderItemCnt === 1
                                  ? " label"
                                  : " labels"}
                                ):
                              </p>
                            </div>
                            <div className="col-4 text-right">
                              <p>
                                $
                                {Number(
                                  this.state.fetchedLabelDetail
                                    .totalAppliedLoyalty +
                                    Number(
                                      this.state.fetchedLabelDetail.item.order
                                        .orderTotal
                                    )
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-8">
                              {" "}
                              <p className="">Loyalty Credit Applied:</p>
                            </div>
                            <div className="col-4 text-right">
                              <p>
                                - $
                                {this.state.fetchedLabelDetail.totalAppliedLoyalty.toFixed(
                                  2
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                      {this.state.fetchedLabelDetail.totalAppliedLoyalty ? (
                        <div className="row service-type-table-footer">
                          <div className="col-8">
                            <p>
                              <strong>Order Total</strong>
                            </p>
                          </div>
                          <div className="col-4 text-right">
                            <p>
                              <strong>
                                {" "}
                                {this.state.fetchedLabelDetail
                                  .totalAppliedLoyalty
                                  ? this.state.fetchedLabelDetail.orderTotal
                                  : this.state.fetchedLabelDetail.item.orderItemCost.ttlShipCostAmt.toFixed(
                                      2
                                    )}
                              </strong>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="view-label-messages">
                <div className="col-12 col-md-10 form-group">
                  <div className="row view-label-messages-header">
                    <div className="col-4 text-right">
                      <p>
                        <strong>Time Stamp</strong>
                      </p>
                    </div>
                    <div className="col-8">
                      <p>
                        <strong>Message</strong>
                      </p>
                    </div>
                  </div>

                  {this.state.fetchedLabelDetail.item.orderItemAudits.map(
                    (item) => (
                      <div className="row" key={item.orderItemAuditId}>
                        <div className="col-4 text-right">
                          <p>{item.formattedCreateDate}</p>
                        </div>
                        <div className="col-8">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.note
                            }}
                          ></p>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {this.state.fetchedLabelDetail.item.purgeByDate != null &&
                this.state.fetchedLabelDetail.item
                  .extendRetentionPurchasedCode === null ? (
                  <p style={{ color: "red" }}>
                    Tracking for this label is available until{" "}
                    {this.state.fetchedLabelDetail.item.purgeByDateFormatted}.
                    Need to keep Tracking history longer? Find out if your label
                    is eligible for{" "}
                    <a
                      id="pturl"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        this.props.lookupCodes["cns.premium.tracking.sh.url"]
                      }
                    >
                      USPS Tracking®
                    </a>{" "}
                    today!
                  </p>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}{" "}
      </React.Fragment>
    );
  }
}

export default withRouter(LabelDetails);
