import React from "react";
import Moment from "moment";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as analytics from "../Analytics/Analytics.js";

import sortIcon from "../../click-n-ship/images/sort-icon.svg";
import RequestARefundModal from "../Modals/RequestARefundModal";
import CancelARefundModal from "../Modals/CancelARefundModal";
import PageNumbers from "../Subcomponents/PageNumbers";

class ShippingHistoryTable extends React.PureComponent {
  constructor(props) {
    super(props);

    // create data set of random length
    this.dataSet = this.props.shippingHistoryInit;

    this.state = {
      totalLabelsSelected: 0,
      t: this.props.shippingHistoryInit,
      actionSelected: "track",
      disableCheckbox: false,
      selectedOrderItemList: [],
      selectedTrackingNumberList: [],
      dateAsc: undefined,
      orderAsc: undefined,
      serviceAsc: undefined,
      addressAsc: undefined,
      labelAsc: undefined,
      shippedAsc: undefined,
      paymentAsc: undefined,
      statusAsc: undefined,
      costAsc: undefined
    };
  }

  handleClick = (index) => {
    this.props.fetchSortedShippingHistory(index);
  };

  goToLabelDetails = (e, orderItemId) => {
    e.preventDefault();
    this.props.history.push("/labelDetails", {
      orderItemId: orderItemId
    });
  };

  selectLabel = (isChecked, orderItem) => {
    if (isChecked) {
      // Set canShipAgain to false for GXG labels
      if (orderItem.productCode === "GXG") {
        orderItem.canShipAgain = false;
      }


      // List of selected order item IDs
      let joined = this.state.selectedOrderItemList.concat(
        orderItem.orderItemId
      );
      // List of selected tracking numbers
      let joinedTrackingNumbers = this.state.selectedTrackingNumberList.concat(
        orderItem.trackingNbr
      );
      let holdDisableCheckbox = false;
      
      if (
        // Tracking action
        (this.state.actionSelected === "track" &&
          this.state.totalLabelsSelected < 20) ||
        // Ship again action
        (this.state.actionSelected === "shipAgain" &&
          this.state.t.cartSummaryViewBean.cartCount +
            this.state.totalLabelsSelected <
            50 &&
          orderItem.canShipAgain === true) ||
        // Print action
        (this.state.actionSelected === "print" &&
          orderItem.canPrint === true) ||
        // Save as PDF action
        (this.state.actionSelected === "savePdf" &&
          orderItem.canSave === true) ||
        // Refund action
        (this.state.actionSelected === "refund" &&
          orderItem.canRefund === true) ||
        // Cancel refund action
        (this.state.actionSelected === "cancelRefund" &&
          orderItem.canCancelRefund === true)
      ) {
        this.setState((prevState) => {
          return {
            totalLabelsSelected: prevState.totalLabelsSelected + 1,
            selectedOrderItemList: joined,
            selectedTrackingNumberList: joinedTrackingNumbers,
            disableCheckbox: holdDisableCheckbox
          };
        });
      }
    } else {
      // Uncheck the box and remove the item from the lists
      this.setState((state) => {
        const selectedOrderItemList = state.selectedOrderItemList.filter(
          (item, j) => orderItem.orderItemId !== item
        );
        const selectedTrackingNumberList = state.selectedTrackingNumberList.filter(
          (item, j) => orderItem.trackingNbr !== item
        );
        return {
          selectedOrderItemList,
          selectedTrackingNumberList
        };
      });
      this.setState({
        totalLabelsSelected: this.state.totalLabelsSelected - 1,
        actionSelected:
          this.state.totalLabelsSelected - 1 <= 0
            ? "track"
            : this.state.actionSelected
      });
    }
  };

  changeAction = (action) => {
    let selectedOrderItemListHold = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectedOrderItemList))
    );
    let selectedTrackingNumberListHold = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectedTrackingNumberList))
    );
    // let selectedOrderItemListFilter = [];
    if (action === "track") {
      if (this.state.selectedOrderItemList.length > 20) {
        this.setState((prevState) => {
          return {
            selectedOrderItemList: prevState.selectedOrderItemList.slice(0, 20),
            selectedTrackingNumberList: prevState.selectedTrackingNumberList.slice(
              0,
              20
            ),
            actionSelected: action
          };
        });
      }
    }
    if (action === "shipAgain") {
      if (
        this.state.t.cartSummaryViewBean.cartCount +
          this.state.totalLabelsSelected >
        20
      ) {
        this.setState((prevState) => {
          return {
            selectedOrderItemList: prevState.selectedOrderItemList.slice(
              0,
              20 - prevState.t.cartSummaryViewBean.cartCount
            ),
            selectedTrackingNumberList: prevState.selectedTrackingNumberList.slice(
              0,
              20 - prevState.t.cartSummaryViewBean.cartCount
            ),
            actionSelected: action,
            totalLabelsSelected: 20 - prevState.t.cartSummaryViewBean.cartCount
          };
        });
      }
    }
    for (let i = 0; i < this.state.prevState.length; i++) {
      if (
        (action === "print" && this.state.prevState[i].canPrint === false) ||
        (action === "savePdf" && this.state.prevState[i].canSave === false) ||
        (action === "refund" && this.state.prevState[i].canRefund === false) ||
        (action === "cancelRefund" &&
          this.state.prevState[i].canCancelRefund === false) ||
        (action === "shipAgain" &&
          this.state.prevState[i].canShipAgain === false)
      ) {
        if (
          this.state.selectedOrderItemList.includes(
            this.state.prevState[i].orderItemId
          )
        ) {
          //   this.setState(state => {
          // selectedOrderItemListFilter = this.state.selectedOrderItemList.filter((item, j) => this.state.prevState[i].orderItemId !== item);

          //     return {
          //       selectedOrderItemList
          //     };
          //     totalLabelsSelected: this.state.selectedOrderItemList.length;
          //     actionSelected: action;
          //   });
          //   for (let j = 0; j < selectedOrderItemListHold.length; j++) {
          //     if (this.state.prevState[i].orderItemId === selectedOrderItemListHold[j]) {
          //       selectedOrderItemListFilter = selectedOrderItemListHold.splice(j, 1);

          //       //   if (j === 0) {
          //       //     selectedOrderItemListFilter = [];
          //       //     break;
          //       //   }
          //     }
          //   }

          selectedOrderItemListHold.splice(
            selectedOrderItemListHold.indexOf(
              this.state.prevState[i].orderItemId
            ),
            1
          );
          selectedTrackingNumberListHold.splice(
            selectedTrackingNumberListHold.indexOf(
              this.state.prevState[i].trackingNbr
            ),
            1
          );
        }
      }
    }

    if (
      action === "print" ||
      action === "savePdf" ||
      action === "refund" ||
      action === "cancelRefund" ||
      action === "shipAgain"
    ) {
      this.setState({
        selectedOrderItemList: selectedOrderItemListHold,
        selectedTrackingNumberList: selectedTrackingNumberListHold,

        totalLabelsSelected: selectedOrderItemListHold.length,
        actionSelected:
          selectedOrderItemListHold.length === 0 ? "track" : action
      });
    } else {
      if (action !== "shipAgain") {
        this.setState({
          actionSelected: action,
          totalLabelsSelected: this.state.selectedOrderItemList.length
        });
      } else {
        this.setState({
          actionSelected: action,
          selectedOrderItemList: this.state.selectedOrderItemList.slice(
            0,
            20 - this.state.t.cartSummaryViewBean.cartCount
          ),
          selectedTrackingNumberList: this.state.selectedTrackingNumberList.slice(
            0,
            20 - this.state.t.cartSummaryViewBean.cartCount
          )
        });
      }
    }
  };

  selectAllLabels = (checked) => {
    let topTwentySelectedOrderItems = [];
    let topTwentySelectedTrackingNumbers = [];
    if (checked) {
      // Loop is restricted to the number of results per page or 20,
      // whichever is lower
      for (let i = 0; i < this.state.prevState.length && i < 20; i++) {
        if (
          // Tracking action
          (this.state.actionSelected === "track" &&
            this.state.totalLabelsSelected < 20) ||
          // Ship again action
          (this.state.actionSelected === "shipAgain" &&
            this.state.t.cartSummaryViewBean.cartCount +
              this.state.totalLabelsSelected <
              20) ||
          // Print action
          (this.state.actionSelected === "print" &&
            this.state.prevState[i].canPrint === true) ||
          // Save as PDF action
          (this.state.actionSelected === "savePdf" &&
            this.state.prevState[i].canSave === true) ||
          // Refund action
          (this.state.actionSelected === "refund" &&
            this.state.prevState[i].canRefund === true) ||
          // Cancel refund action
          (this.state.actionSelected === "cancelRefund" &&
            this.state.prevState[i].canCancelRefund === true)
        ) {

          // Set canShipAgain to false for GXG labels
          if (this.state.prevState[i].productCode === "GXG") {
            this.state.prevState[i].canShipAgain = false;
          }

          topTwentySelectedOrderItems[i] = this.state.prevState[i].orderItemId;
          topTwentySelectedTrackingNumbers[i] = this.state.prevState[
            i
          ].trackingNbr;
        }
      }
      this.setState({
        selectedOrderItemList: topTwentySelectedOrderItems,
        selectedTrackingNumberList: topTwentySelectedTrackingNumbers,
        totalLabelsSelected: topTwentySelectedOrderItems.length
      });
    } else {
      this.setState({
        selectedOrderItemList: [],
        selectedTrackingNumberList: [],
        totalLabelsSelected: 0,
        actionSelected: "track"
      });
    }
  };

  isDisabled = (data) => {
    if (
      (this.state.disableCheckbox &&
        data.canPrint === false &&
        this.state.actionSelected === "print") ||
      (data.canRefund === false && this.state.actionSelected === "refund") ||
      (data.canSave === false && this.state.actionSelected === "savePdf") ||
      (this.state.t.cartSummaryViewBean.cartCount +
        this.state.totalLabelsSelected >
        20 &&
        this.state.actionSelected === "shipAgain" &&
        data.canShipAgain === true) ||
      (this.state.totalLabelsSelected > 20 &&
        this.state.actionSelected === "track") ||
      (data.canCancelRefund === false &&
        this.state.actionSelected === "cancelRefund")
    ) {
      return true;
    }
    return false;
  };

  getTotalPrice = (e) => {
    let totalCalculated = 0;
    for (let i = 0; i < this.props.recordsPerPage; i++) {
      if (this.state.prevState[i] !== undefined) {
        totalCalculated += this.state.prevState[i].ttlShipCostAmt;
      }
    }
    if (totalCalculated > 0) {
      return totalCalculated.toFixed(2);
    } else {
      return totalCalculated;
    }
  };

  requestARefund = () => {
    let postData = {
      startPage: "1",
      recordsPerPage: "20",
      requestType: "1",
      firstName: "",
      lastName: "",
      company: "",
      orderId: "",
      trackingNumber: "",
      startDate: "",
      orderItemIds: this.state.selectedOrderItemList.join(","),
      returnTo: "historyjson"
    };

    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/requestRefund", postData)
      .then((response) => {
        this.props.refreshOrderItems();
        this.setState({
          selectedOrderItemList: [],
          selectedTrackingNumberList: [],
          totalLabelsSelected: 0,
          actionSelected: "track"
        });
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        console.log(error);
      });
  };

  cancelARefund = () => {
    let postData = {
      startPage: "1",
      recordsPerPage: "20",
      requestType: "1",
      firstName: "",
      lastName: "",
      company: "",
      orderId: "",
      trackingNumber: "",
      startDate: "",
      orderItemIds: this.state.selectedOrderItemList.join(","),
      returnTo: "historyjson"
    };

    this.props.toggleSpinner(true);
    axios
      .post("/go/cnsrest/cancelRefund", postData)
      .then((response) => {
        this.props.refreshOrderItems();
        this.setState({
          selectedOrderItemList: [],
          selectedTrackingNumberList: [],
          totalLabelsSelected: 0,
          actionSelected: "track"
        });
      })
      .catch((error) => {
        this.props.toggleSpinner(false);
        console.log(error);
      });
  };

  onGo = (e) => {
    e.preventDefault();
    analytics.shippingHistory_Proceed(this.state.actionSelected);
    if (this.state.actionSelected === "track") {
      window.open(
        this.props.lookupCodes["nav.TrackConfirmAction"] +
          "?tLabels=" +
          this.state.selectedTrackingNumberList.join(",")
      );
    } else if (this.state.actionSelected === "shipAgain") {
      this.setState({
        goDisabled: true
      });
      this.props.toggleSpinner(true);
      window.location =
        "https://" +
        window.location.hostname +
        "/go/cnsrest/recreateCart?orderItemIds=" +
        this.state.selectedOrderItemList.join(",");
    } else if (this.state.actionSelected === "refund") {
      let postData = {
        startPage: "1",
        recordsPerPage: "20",
        requestType: "1",
        firstName: "",
        lastName: "",
        company: "",
        orderId: "",
        trackingNumber: "",
        startDate: "",
        orderItemIds: this.state.selectedOrderItemList.join(","),
        returnTo: "historyjson"
      };

      this.props.toggleSpinner(true);
      axios
        .post("/go/cnsrest/requestRefund", postData)
        .then((response) => {
          this.props.refreshOrderItems();
          this.setState({
            selectedOrderItemList: [],
            selectedTrackingNumberList: []
          });
        })
        .catch((error) => {
          this.props.toggleSpinner(false);
          console.log(error);
        });
    } else if (this.state.actionSelected === "savePdf") {
      let pdfUrl = "";
      pdfUrl =
        "/go/Secure/LabelDownloadServlet?orderItemIds=" +
        this.state.selectedOrderItemList.join(",");
      pdfUrl += "&fileForSave=" + true;
      pdfUrl += "&prtNoInstructions=false";
      pdfUrl += "&manifestNumber=";

      window.location = "https://" + window.location.hostname + pdfUrl;
    } else if (this.state.actionSelected === "cancelRefund") {
      let postData = {
        startPage: "1",
        recordsPerPage: "20",
        requestType: "1",
        firstName: "",
        lastName: "",
        company: "",
        orderId: "",
        trackingNumber: "",
        startDate: "",
        orderItemIds: this.state.selectedOrderItemList.join(","),
        returnTo: "historyjson"
      };

      this.props.toggleSpinner(true);
      axios
        .post("/go/cnsrest/cancelRefund", postData)
        .then((response) => {
          this.props.refreshOrderItems();
          this.setState({
            selectedOrderItemList: [],
            selectedTrackingNumberList: []
          });
        })
        .catch((error) => {
          this.props.toggleSpinner(false);
          console.log(error);
        });
    } else if (this.state.actionSelected === "print") {
      if (this.state.selectedOrderItemList.length > 0) {
        let listOfOrderItemIds = this.state.selectedOrderItemList.join(",");
        let url =
          window.location.protocol +
          "//" +
          window.location.hostname +
          "/go/Secure/LabelDownloadServlet?orderItemIds=" +
          listOfOrderItemIds +
          "&fileForSave=" +
          (e.target.id === "save" ? "true" : "false") +
          "&prtNoInstructions=" +
          (this.state.printOptions === "N") +
          "&manifestNumber=";
        var hiddenFrame = document.createElement("iframe");
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          hiddenFrame.onload = function () {};
          //hiddenFrame.src = iframeHtml;
          hiddenFrame.src = url;
          hiddenFrame.id = "pdfFrame";
          hiddenFrame.name = "pdfFrameName";
          hiddenFrame.style = "visibility: hidden";
          document.body.appendChild(hiddenFrame);
        } else {
          hiddenFrame.onload = function () {
            // invokes the auto-print functionality on the client by calling print on the iframe window
            this.contentWindow.focus();
            this.contentWindow.print();
          };
          //hiddenFrame.src = iframeHtml;
          hiddenFrame.src = url;
          hiddenFrame.style = "visibility: hidden";
          document.body.appendChild(hiddenFrame);
        }
      }
    }
  };

  detectIE = (e) => {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result �

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    //try this as default? May not work in Edge
    //!(window.ActiveXObject) && "ActiveXObject" in window

    // other browser
    return false;
  };

  sortColumns = (e, columnName) => {
    e.preventDefault();
    if (columnName === "cost" && !this.state.costAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) => parseFloat(a.ttlShipCostAmt) - parseFloat(b.ttlShipCostAmt)
        );
        return {
          prevState,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: true
        };
      });
    } else if (columnName === "cost" && this.state.costAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) => parseFloat(b.ttlShipCostAmt) - parseFloat(a.ttlShipCostAmt)
        );
        return {
          prevState,
          costAsc: false
        };
      });
    } else if (columnName === "status" && !this.state.statusAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.statusDescription.localeCompare(b.statusDescription)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: true,
          costAsc: undefined
        };
      });
    } else if (columnName === "status" && this.state.statusAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.statusDescription.localeCompare(a.statusDescription)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          statusAsc: false
        };
      });
    } else if (columnName === "payment" && !this.state.paymentAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.paymentTypeDesc.localeCompare(b.paymentTypeDesc)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: true,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "payment" && this.state.paymentAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.paymentTypeDesc.localeCompare(a.paymentTypeDesc)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          paymentAsc: false
        };
      });
    } else if (columnName === "shipped" && !this.state.shippedAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.shippedInd.localeCompare(b.shippedInd)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: true,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "shipped" && this.state.shippedAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.shippedInd.localeCompare(a.shippedInd)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          shippedAsc: false
        };
      });
    } else if (columnName === "label" && !this.state.labelAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.trackingNbr.localeCompare(b.trackingNbr)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: true,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "label" && this.state.labelAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.trackingNbr.localeCompare(a.trackingNbr)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          labelAsc: false
        };
      });
    } else if (columnName === "shippingAddress" && !this.state.addressAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.displayAddress.localeCompare(b.displayAddress)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: true,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "shippingAddress" && this.state.addressAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.displayAddress.localeCompare(a.displayAddress)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          addressAsc: false
        };
      });
    } else if (columnName === "service" && !this.state.serviceAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          a.productCode.localeCompare(b.productCode)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: undefined,
          serviceAsc: true,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "service" && this.state.serviceAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort((a, b) =>
          b.productCode.localeCompare(a.productCode)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          serviceAsc: false
        };
      });
    } else if (columnName === "order" && !this.state.orderAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) => parseFloat(a.orderId) - parseFloat(b.orderId)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: undefined,
          orderAsc: true,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "order" && this.state.orderAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) => parseFloat(b.orderId) - parseFloat(a.orderId)
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          orderAsc: false
        };
      });
    } else if (columnName === "date" && !this.state.dateAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) =>
            parseFloat(Moment(a.transactionDtm, "MM/DD/YYYY").unix()) -
            parseFloat(Moment(b.transactionDtm, "MM/DD/YYYY").unix())
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: true,
          orderAsc: undefined,
          serviceAsc: undefined,
          addressAsc: undefined,
          labelAsc: undefined,
          shippedAsc: undefined,
          paymentAsc: undefined,
          statusAsc: undefined,
          costAsc: undefined
        };
      });
    } else if (columnName === "date" && this.state.dateAsc) {
      this.setState((oldState) => {
        const prevState = oldState.prevState.sort(
          (a, b) =>
            parseFloat(Moment(b.transactionDtm, "MM/DD/YYYY").unix()) -
            parseFloat(Moment(a.transactionDtm, "MM/DD/YYYY").unix())
        );
        return {
          prevState,
          selectedOrderItemList: oldState.selectedOrderItemList,
          dateAsc: false
        };
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      prevState: nextProps.shippingHistoryInit.orders,
      filterPrefs: nextProps.columnFilterPrefs
    };
  }

  render() {
    // Get the total number of pages
    let pagesCount;
    if (this.props.shippingHistoryInit.orders[0]) {
      pagesCount = Math.ceil(
        this.props.shippingHistoryInit.orders[0].totalRows /
          this.props.recordsPerPage
      );
    } else {
      pagesCount = 1;
    }
    // Update the page numbers that should be displayed
    let pageNumbersToDisplay = [];
    // The current page
    pageNumbersToDisplay.push(this.props.pageNum);
    // Add up to 2 pages before the current page, if available
    if (this.props.pageNum - 1 >= 1) {
      pageNumbersToDisplay.unshift(this.props.pageNum - 1);
      if (this.props.pageNum - 2 >= 1) {
        pageNumbersToDisplay.unshift(this.props.pageNum - 2);
      }
    }
    // Add pages after the current page until either the total number is 5 or
    // the last page has been reached
    for (
      let i = this.props.pageNum + 1;
      pageNumbersToDisplay.length < 5 && i <= pagesCount;
      i++
    ) {
      pageNumbersToDisplay.push(i);
    }
    // If the total number of pages is still less than 5 and the first page
    // has not been included yet, prepend pages to the array
    for (
      let j = this.props.pageNum - 3;
      pageNumbersToDisplay.length < 5 && j >= 1;
      j--
    ) {
      pageNumbersToDisplay.unshift(j);
    }
    return (
      <React.Fragment>
        {this.state.totalLabelsSelected > 0 ? (
          <div className="row labels-selected-container">
            <iframe
              id="iePdfFrame"
              title="Label PDF"
              style={{ display: "none" }}
            />
            <div className="col-12 col-md-10 form-group">
              <p className="num-labels-selected">
                <strong>{this.state.totalLabelsSelected}</strong>
              </p>
              <p className="labels-selected">
                <strong>
                  {"Label" +
                    (this.state.totalLabelsSelected !== 1 ? "s" : "") +
                    " Selected:"}
                </strong>
              </p>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-track-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("track")}
                  checked={this.state.actionSelected === "track"}
                />
                <label htmlFor="labels-track-radio">
                  <strong>Track</strong>
                </label>
              </div>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-ship-again-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("shipAgain")}
                  checked={this.state.actionSelected === "shipAgain"}
                />
                <label htmlFor="labels-ship-again-radio">
                  <strong>Ship Again</strong>
                </label>
              </div>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-print-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("print")}
                  checked={this.state.actionSelected === "print"}
                />
                <label htmlFor="labels-print-radio">
                  <strong>Print</strong>
                </label>
              </div>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-save-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("savePdf")}
                  checked={this.state.actionSelected === "savePdf"}
                />
                <label htmlFor="labels-save-radio">
                  <strong>Save as PDF</strong>
                </label>
              </div>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-refund-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("refund")}
                  checked={this.state.actionSelected === "refund"}
                />
                <label htmlFor="labels-refund-radio">
                  <strong>Refund</strong>
                </label>
              </div>
              <div className="radio-container horizontal d-none d-md-inline-block">
                <input
                  id="labels-cancel-radio"
                  type="radio"
                  className="radio-button"
                  name="labels-selected-radio"
                  tabIndex="0"
                  onChange={(e) => this.changeAction("cancelRefund")}
                  checked={this.state.actionSelected === "cancelRefund"}
                />
                <label htmlFor="labels-cancel-radio">
                  <strong>Cancel Refund</strong>
                </label>
              </div>
            </div>
            <div className="col-8 d-md-none form-group">
              <label>Label actions</label>
              <select
                className="form-control dropdown"
                onChange={(e) => this.changeAction(e.target.value)}
                value={this.state.actionSelected}
              >
                <option value="Select an Action">Select an Action</option>
                <option value="track">Track</option>
                <option value="shipAgain">Ship Again</option>
                <option value="print">Print</option>
                <option value="savePdf">Save as PDF</option>
                <option value="refund">Refund</option>
                <option value="cancelRefund">Cancel Refund</option>
              </select>
            </div>
            {this.state.actionSelected === "refund" ? (
              <div className="col-4 col-md-2 form-group">
                <p>
                  <RequestARefundModal
                    requestARefund={this.requestARefund}
                    buttonName={"Go"}
                    buttonType={"btn-primary"}
                  />
                </p>
              </div>
            ) : this.state.actionSelected === "cancelRefund" ? (
              <div className="col-4 col-md-2 form-group">
                <p>
                  <CancelARefundModal
                    cancelARefund={this.cancelARefund}
                    buttonName={"Go"}
                    buttonType={"btn-primary"}
                  />
                </p>
              </div>
            ) : (
              <div className="col-4 col-md-2 form-group">
                <button
                  type="button"
                  id="go-button"
                  className="btn-primary"
                  tabIndex="0"
                  onClick={(e) => this.onGo(e)}
                  disabled={this.state.goDisabled}
                >
                  Go
                </button>
              </div>
            )}
          </div>
        ) : (
          <React.Fragment />
        )}

        <div className="row">
          <div id="shipping-history-wrapper" className="col-12">
            <table className="col-12 shipping-history">
              <thead>
                <tr className="shipping-history-table-header">
                  <th className={"text-right"}>
                    <label
                      htmlFor="select-all-labels-checkbox"
                      className="checkbox-component"
                    >
                      <input
                        type="checkbox"
                        id="select-all-labels-checkbox"
                        onChange={(e) => this.selectAllLabels(e.target.checked)}
                      />
                      <span className="checkbox" />
                      <span className="visuallyhidden">Select all labels</span>
                    </label>
                  </th>
                  <th className="text-md-right">
                    <button
                      type="button"
                      className="button-link"
                      onClick={(e) => this.sortColumns(e, "date")}
                    >
                      <strong>Date </strong>
                      <img src={sortIcon} alt="Sort icon." />
                      {this.state.dateAsc !== undefined ? (
                        <span className="visuallyhidden">
                          Currently sorted by date in
                          {this.state.dateAsc ? " ascending " : " descending "}
                          order
                        </span>
                      ) : null}
                    </button>
                  </th>
                  {this.state.filterPrefs["Transaction Number"] == null ||
                  this.state.filterPrefs["Transaction Number"] === "S" ? (
                    <th>
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "order")}
                      >
                        <strong>Order # </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.orderAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by order number in
                            {this.state.orderAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Service"] == null ||
                  this.state.filterPrefs["Service"] === "S" ? (
                    <th>
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "service")}
                      >
                        <strong>Service Type </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.serviceAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by service type in
                            {this.state.serviceAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Delivery Address"] == null ||
                  this.state.filterPrefs["Delivery Address"] === "S" ? (
                    <th className="shipping-history-table-force-wrap">
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "shippingAddress")}
                      >
                        <strong>Shipping Address </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.addressAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by shipping address in
                            {this.state.addressAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Label Number"] == null ||
                  this.state.filterPrefs["Label Number"] === "S" ? (
                    <th className="text-md-right">
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "label")}
                      >
                        <strong>Label </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.labelAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by label number in
                            {this.state.labelAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Shipping Status"] == null ||
                  this.state.filterPrefs["Shipping Status"] === "S" ? (
                    <th>
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "shipped")}
                      >
                        <strong>Shipped </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.shippedAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by shipped status in
                            {this.state.shippedAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Payment Method"] == null ||
                  this.state.filterPrefs["Payment Method"] === "S" ? (
                    <th>
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "payment")}
                      >
                        <strong>Payment </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.paymentAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by payment method in
                            {this.state.paymentAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Payment Status"] == null ||
                  this.state.filterPrefs["Payment Status"] === "S" ? (
                    <th>
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "status")}
                      >
                        <strong>Status </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.statusAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by payment status in
                            {this.state.statusAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.filterPrefs["Label Cost"] == null ||
                  this.state.filterPrefs["Label Cost"] === "S" ? (
                    <th className="text-md-right">
                      <button
                        type="button"
                        className="button-link"
                        onClick={(e) => this.sortColumns(e, "cost")}
                      >
                        <strong>Cost </strong>
                        <img src={sortIcon} alt="Sort icon." />
                        {this.state.costAsc !== undefined ? (
                          <span className="visuallyhidden">
                            Currently sorted by cost in
                            {this.state.costAsc
                              ? " ascending "
                              : " descending "}
                            order
                          </span>
                        ) : null}
                      </button>
                    </th>
                  ) : (
                    <React.Fragment />
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.prevState.length > 0
                  ? this.state.prevState.map((data, i) => (
                      <tr
                        valign="top"
                        className="list-items data-slice"
                        key={data.orderItemId}
                      >
                        <td className="text-right">
                          <label
                            htmlFor={"label-list" + data.orderItemId}
                            className="checkbox-component"
                          >
                            <input
                              id={"label-list" + data.orderItemId}
                              type="checkbox"
                              onChange={(e) =>
                                this.selectLabel(e.target.checked, data)
                              }
                              disabled={this.isDisabled(data)}
                              style={
                                this.state.disableCheckbox ||
                                (data.canPrint === false &&
                                  this.state.actionSelected === "print") ||
                                (data.canRefund === false &&
                                  this.state.actionSelected === "refund") ||
                                (data.canSave === false &&
                                  this.state.actionSelected === "savePdf") ||
                                (this.state.t.cartSummaryViewBean.cartCount +
                                  this.state.totalLabelsSelected >
                                  20 &&
                                  this.state.actionSelected === "shipAgain" &&
                                  data.canShipAgain === false) ||
                                (this.state.totalLabelsSelected > 20 &&
                                  this.state.actionSelected === "track") ||
                                (data.canCancelRefund === false &&
                                  this.state.actionSelected ===
                                    "cancelRefund") === true
                                  ? { opacity: "0.2" }
                                  : { opacity: "1" }
                              }
                              checked={this.state.selectedOrderItemList.includes(
                                data.orderItemId
                              )}
                            />
                            <span className="checkbox" />
                          </label>
                        </td>
                        <td className="text-md-right">
                          <p>
                            {" "}
                            {Moment(data.shippingDate).format("MM/DD/YYYY")}
                          </p>
                        </td>

                        {this.state.filterPrefs["Transaction Number"] == null ||
                        this.state.filterPrefs["Transaction Number"] === "S" ? (
                          <td>
                            <p>{data.orderId}</p>
                            {data.hasDimensions ? <p>Dimensions</p> : null}
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Service"] == null ||
                        this.state.filterPrefs["Service"] === "S" ? (
                          <td>
                            {data.commercialInd !== "Y" ? (
                              <p>{data.productCode}</p>
                            ) : (
                              <p>{data.productCode} - CB </p>
                            )}
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Delivery Address"] == null ||
                        this.state.filterPrefs["Delivery Address"] === "S" ? (
                          <td className="shipping-history-table-force-wrap">
                            <p>{data.fullOrCompanyName}</p>

                            <p>{data.line1Addr}</p>

                            <p>
                              {data.cityStateOrCountry} {data.postalCode}
                            </p>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Label Number"] == null ||
                        this.state.filterPrefs["Label Number"] === "S" ? (
                          <td className="text-md-right">
                            <a
                              href={
                                "/labelDetails?orderItemId=" + data.orderItemId
                              }
                              className="inline-link secondary label-number"
                              onClick={(e) =>
                                this.goToLabelDetails(e, data.orderItemId)
                              }
                            >
                              {data.trackingNbr &&
                                data.trackingNbr
                                  .split(",")
                                  .map((trackingNbr) => (
                                    <React.Fragment>
                                      {trackingNbr}
                                      <br />
                                    </React.Fragment>
                                  ))}
                            </a>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Shipping Status"] == null ||
                        this.state.filterPrefs["Shipping Status"] === "S" ? (
                          <td>
                            <p>{data.shippedInd === "Y" ? "Yes" : "No"}</p>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Payment Method"] == null ||
                        this.state.filterPrefs["Payment Method"] === "S" ? (
                          <td>
                            <p>{data.paymentTypeDesc}</p>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Payment Status"] == null ||
                        this.state.filterPrefs["Payment Status"] === "S" ? (
                          <td>
                            <p>{data.statusDescription}</p>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}

                        {this.state.filterPrefs["Label Cost"] == null ||
                        this.state.filterPrefs["Label Cost"] === "S" ? (
                          <td className="text-md-right">
                            <p>
                              $
                              {data.amtLoyalty !== undefined
                                ? Number(
                                    data.ttlShipCostAmt +
                                      Number(data.amtLoyalty)
                                  ).toFixed(2)
                                : data.ttlShipCostAmt.toFixed(2)}
                            </p>
                          </td>
                        ) : (
                          <React.Fragment />
                        )}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            {this.state.prevState.length === 0 ? (
              <p id="shipping-history-empty-table">No results</p>
            ) : null}
          </div>
          <div className="col-12 label-total-container">
            <div className="label-total">
              <div className="total-cost">
                <p>${this.getTotalPrice()}</p>
              </div>
              <div className="labels-num">
                <p>Labels Total ({this.state.prevState.length}):</p>
              </div>
            </div>
          </div>
        </div>
        <div id="shipping-history-footer" className="col-12 form-group">
          <div className="row">
            <div className="col-6 d-none d-md-inline-flex col-lg-3 results-dropdown-container">
              <strong className="results-label">Results Per Page:</strong>
              <select
                className="form-control results-amount dropdown"
                onChange={(e) =>
                  this.props.fetchSortedShippingHistory(1, e.target.value)
                }
                value={this.props.recordsPerPage}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
              </select>
            </div>
            <PageNumbers
              divId="shipping-history-table-pages-id"
              divClassName="results-pagination-wrapper"
              liClassName="page-item"
              currentPage={this.props.pageNum}
              setCurrentPage={this.handleClick}
              pageNumbersToDisplay={pageNumbersToDisplay}
              numOfPages={pagesCount}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ShippingHistoryTable);
